import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Fragment, FunctionComponent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router";
import { AppContext } from "../app/context/app-context";
import { ConfirmationDialog } from "../components/confirmation-dialog/confirmation-dialog";
import { Header } from "../components/header/header";
import { Progress } from "../components/progress/progress";
import { HearingProtectionTestContext } from "../pages/hearing-protection-test/context/hearing-protection-test-context";
import {
  APP_BAR_HEIGHT,
  PAGE_MAX_WIDTH,
  PROGRESS_BAR_HEIGHT,
  CANCEL_BTN_SPACING,
  PAGE_BOTTOM_PADDING,
} from "../theme/theme";
import { use100vh } from "react-div-100vh";
import { ROUTES } from "../router/router";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

export interface IDefaultLayoutProps {
  canCancel: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  absolute: {
    position: "absolute",
    minWidth: "100vw",
    flexWrap: "nowrap",
    display: "flex",
  },
  container: {
    width: "100%",
    minHeight: "100%",
    flex: 1,
  },
  cancelContainer: {
    height: CANCEL_BTN_SPACING,
    width: "100%",
    maxWidth: PAGE_MAX_WIDTH * 1.5,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    flex: 1,
  },
  cancelButton: {
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
    color: theme.palette.grey["300"],
  },
  content: {
    width: "100%",
    maxWidth: PAGE_MAX_WIDTH,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    flex: 1,
  },
  topBar: {
    position: "fixed",
    display: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1000,
  },
  dummyElement: {
    height: APP_BAR_HEIGHT + PROGRESS_BAR_HEIGHT,
  },
  versionNumberContainer: {
    paddingBottom: PAGE_BOTTOM_PADDING,
  },
}));

export const DefaultLayout: FunctionComponent<IDefaultLayoutProps> = (props) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const screenHeight = use100vh();

  const history = useHistory();
  const { t } = useTranslation();
  const { cancelTest } = useContext(HearingProtectionTestContext);

  const onCancelTest = useCallback(() => {
    setIsDialogOpen(false);
    cancelTest();
    history.push(generatePath(ROUTES.LANDING.PATH));
  }, [history, cancelTest]);

  const onContinueTest = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  const { progress } = useContext(AppContext);

  return (
    <div className={classes.absolute} style={{ minHeight: screenHeight || undefined }}>
      <Fragment>
        <Grid container direction="column" className={classes.container}>
          <Grid item className={classes.topBar}>
            <Header />
            <Progress progress={progress} />
          </Grid>
          <Grid className={classes.dummyElement} />

          <Grid item container justify="center">
            <Grid container direction="row" className={`${classes.cancelContainer}`}>
              <Grid item container direction="row" justify="flex-end" alignItems="center" xs={12}>
                <Grid item>
                  {props.canCancel && (
                    <Button className={classes.cancelButton} onClick={() => setIsDialogOpen(true)}>
                      {t("cancel_test.cancel_test")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" xs>
            <Grid item container className={classes.content}>
              {props.children}
            </Grid>
          </Grid>
          <Grid item container justify="center" className={classes.versionNumberContainer}>
            <Typography align="center" variant="caption">
              {t("general.version", { version: env.REACT_APP_VERSION_NUMBER })}
            </Typography>
          </Grid>
        </Grid>

        {props.canCancel && (
          <ConfirmationDialog
            open={isDialogOpen}
            heading={t("cancel_test.heading")}
            content={t("cancel_test.content")}
            leftActionText={t("cancel_test.action_1")}
            rightActionText={t("cancel_test.action_2")}
            rightAction={onCancelTest}
            leftAction={onContinueTest}
          />
        )}
      </Fragment>
    </div>
  );
};
