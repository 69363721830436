import React, { FunctionComponent } from "react";
import { Theme, makeStyles, DialogContent, DialogContentProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
}));

interface IResponsiveDialogContentProps extends DialogContentProps {}

export const ResponsiveDialogContent: FunctionComponent<IResponsiveDialogContentProps> = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const cn = className ? `${classes.dialogContent} ${className}` : classes.dialogContent;
  return (
    <DialogContent {...rest} className={cn}>
      {children}
    </DialogContent>
  );
};
