import { FunctionComponent } from "react";
import { DefaultLayout } from "../layouts/default-layout";
import { LandingPage } from "../pages/onboarding/landing-page";
import { AdjustVolumeAccessControl, AdjustVolumePage } from "../pages/adjust-volume/adjust-volume-page";
import { DataInputPage } from "../pages/data-input/data-input-page";
import { ProductPage } from "../pages/product/product-page";
import {
  HearingProtectionTestAccessControl,
  HearingProtectionTestPage,
} from "../pages/hearing-protection-test/hearing-protection-test-page";
import { TestFailureAccessControl, TestFailurePage } from "../pages/test-failure/test-failure-page";
import { OverviewPage } from "../pages/overview/overview-page";
import { SecurityInformationPage } from "../pages/security-information/security-information-page";
import { EarwearAccountPage } from "../pages/earwear-account/earwear-account-page";
import { IAppContextProps } from "../app/context/app-context";
import { IHearingProtectionTestContextProps } from "../pages/hearing-protection-test/context/hearing-protection-test-context";
import { SummaryPage } from "../pages/summary/summary-page";

export interface IContexts {
  appContext: IAppContextProps;
  hearingProtectionTestContext: IHearingProtectionTestContextProps;
}
export interface IRouteInfo {
  PATH: string;
  COMPONENT: FunctionComponent<any>;
  LAYOUT?: FunctionComponent<any>;
  LAYOUT_PROPS?: any;
  PAGE_PROPS?: any;
  ACCESS_CHECK?: (contexts: IContexts) => boolean;
  REDIRECT_PATH?: () => string;
}
interface IRoutes {
  LANDING: IRouteInfo;
  DATA_INPUT: IRouteInfo;
  PRODUCT: IRouteInfo;
  // HEADPHONES_OR_SPEAKER: IRouteInfo;
  ADJUST_VOLUME: IRouteInfo;
  HEARING_PROTECTION_TEST: IRouteInfo;
  TEST_FAILURE: IRouteInfo;
  OVERVIEW: IRouteInfo;
  SECURITY_INFORMATION: IRouteInfo;
  EARWEAR_ACCOUNT: IRouteInfo;
  SUMMARY: IRouteInfo;
}

export const ROUTES: IRoutes = {
  LANDING: {
    PATH: "/landing",
    COMPONENT: LandingPage,
  },
  DATA_INPUT: {
    PATH: "/your-information",
    COMPONENT: DataInputPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
  },
  PRODUCT: {
    PATH: "/product",
    COMPONENT: ProductPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
  },
  // For future use
  // HEADPHONES_OR_SPEAKER: {
  //   PATH: "/headphones-or-speaker",
  //   COMPONENT: HeadphonesOrSpeakerPage,
  //   LAYOUT: DefaultLayout,
  //   LAYOUT_PROPS: {
  //     canCancel: true,
  //   },
  // },
  ADJUST_VOLUME: {
    PATH: "/adjust-volume",
    COMPONENT: AdjustVolumePage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
    ACCESS_CHECK: AdjustVolumeAccessControl,
    // REDIRECT_PATH: () => ROUTES.HEADPHONES_OR_SPEAKER.PATH,
    REDIRECT_PATH: () => ROUTES.PRODUCT.PATH,
  },
  HEARING_PROTECTION_TEST: {
    PATH: "/hearing-protection-test/:step",
    COMPONENT: HearingProtectionTestPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
    ACCESS_CHECK: HearingProtectionTestAccessControl,
  },
  TEST_FAILURE: {
    PATH: "/hearing-protection-test-fail",
    COMPONENT: TestFailurePage,
    LAYOUT: DefaultLayout,
    ACCESS_CHECK: TestFailureAccessControl,
  },
  OVERVIEW: {
    PATH: "/overview",
    COMPONENT: OverviewPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
    ACCESS_CHECK: (ctx) => {
      return !!ctx.hearingProtectionTestContext.areReadingsReady;
    },
  },
  SECURITY_INFORMATION: {
    PATH: "/security-information",
    COMPONENT: SecurityInformationPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
    ACCESS_CHECK: (ctx: IContexts) => {
      return !!ctx.hearingProtectionTestContext.areReadingsReady && !ctx.appContext.isDataInputSkipped;
    },
  },
  EARWEAR_ACCOUNT: {
    PATH: "/earwear-account",
    COMPONENT: EarwearAccountPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: false,
    },
    ACCESS_CHECK: (ctx) => {
      return !!ctx.hearingProtectionTestContext.areReadingsReady && !ctx.appContext.isDataInputSkipped;
    },
  },
  SUMMARY: {
    PATH: "/summary",
    COMPONENT: SummaryPage,
    LAYOUT: DefaultLayout,
    LAYOUT_PROPS: {
      canCancel: true,
    },
    ACCESS_CHECK: (ctx) => {
      return !!ctx.hearingProtectionTestContext.areReadingsReady && !ctx.appContext.isDataInputSkipped;
    },
  },
};
