import { FunctionComponent, useCallback } from "react";
import { DialogActions, Grid, makeStyles, Theme } from "@material-ui/core";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { ResponsiveDialog } from "../../components/dialog/responsive-dialog";
import { ResponsiveDialogTitle } from "../../components/dialog/responsive-dialog-title";
import { ResponsiveDialogContent } from "../../components/dialog/responsive-dialog-content";
import { useHistory } from "react-router";
import { ROUTES } from "../../router/router";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    whiteSpace: "pre-line",
  },
  leftButton: {
    width: "100%",
    fontSize: 14,
  },
  rightButton: {
    width: "100%",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
  },
  heading: {
    textAlign: "center",
  },
  icon: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: 60,
    height: 60,
  },
}));

interface ICertificateSentDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CertificateSentDialog: FunctionComponent<ICertificateSentDialogProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { open, onClose } = props;

  const onClick = useCallback(() => {
    onClose();
    history.push({
      pathname: ROUTES.EARWEAR_ACCOUNT.PATH,
    });
  }, [history, onClose]);

  return (
    <ResponsiveDialog open={open} fullWidth maxWidth="sm">
      <ResponsiveDialogTitle className={classes.heading}>
        {t("summary.certificate_sent_dialog.content")}
      </ResponsiveDialogTitle>
      <ResponsiveDialogContent>
        <Grid container justify="center">
          <CheckCircle color="secondary" className={classes.icon} />
        </Grid>
      </ResponsiveDialogContent>
      <DialogActions>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <PrimaryActionButton className={classes.rightButton} variant="contained" onClick={onClick}>
              {t("general.next")}
            </PrimaryActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};
