import React, { FunctionComponent } from "react";
import { Theme, makeStyles, Dialog, DialogProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2)
    }
  }
}));

interface IResponsiveDialogProps extends DialogProps {}

export const ResponsiveDialog: FunctionComponent<IResponsiveDialogProps> = props => {
  const { children, classes, ...rest } = props;
  const myClasses = useStyles();

  const paper = classes?.paper ? `${myClasses.paper} ${classes.paper}` : myClasses.paper;

  return (
    <Dialog {...rest} classes={{ paper }}>
      {children}
    </Dialog>
  );
};
