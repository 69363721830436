import { FunctionComponent, ReactNode } from "react";
import { Button, DialogActions, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { PrimaryActionButton } from "../button/primary-action-button";
import { ResponsiveDialog } from "../dialog/responsive-dialog";
import { ResponsiveDialogTitle } from "../dialog/responsive-dialog-title";
import { ResponsiveDialogContent } from "../dialog/responsive-dialog-content";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    whiteSpace: "pre-line",
  },
  leftButton: {
    width: "100%",
    fontSize: 14,
  },
  rightButton: {
    width: "100%",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
  },
}));

interface IConfirmationDialogProps {
  open: boolean;
  heading?: string;
  content?: string;
  leftActionText?: ReactNode;
  leftAction?: () => void;
  leftActionDisabled?: boolean;
  rightActionText?: ReactNode;
  rightAction?: () => void;
  rightActionDisabled?: boolean;
}

export const ConfirmationDialog: FunctionComponent<IConfirmationDialogProps> = (props: IConfirmationDialogProps) => {
  const classes = useStyles();

  return (
    <ResponsiveDialog open={props.open} fullWidth maxWidth="sm">
      {props.heading && <ResponsiveDialogTitle>{props.heading}</ResponsiveDialogTitle>}
      <ResponsiveDialogContent>
        <Grid container>
          <Typography className={classes.content} variant="body2">
            {props.content}
          </Typography>
        </Grid>
      </ResponsiveDialogContent>
      <DialogActions>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={6}>
            {props.leftActionText && props.leftAction && (
              <Button className={classes.leftButton} onClick={props.leftAction} disabled={props.leftActionDisabled}>
                {props.leftActionText}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {props.rightAction && props.rightActionText && (
              <PrimaryActionButton
                className={classes.rightButton}
                variant="contained"
                onClick={props.rightAction}
                disabled={props.rightActionDisabled}
              >
                {props.rightActionText}
              </PrimaryActionButton>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
};
