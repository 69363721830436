import { Fragment, FunctionComponent, useContext, useState, useCallback, useMemo, useEffect } from "react";
import { Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../app/context/app-context";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog";
import { PageHeading } from "../../components/typography/page-heading";
import { PageActions } from "../../components/containers/page-actions";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { generatePath, useHistory } from "react-router";
import { ROUTES } from "../../router/router";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";

const useStyles = makeStyles((theme: Theme) => ({
  spacingBottom: {
    marginBottom: theme.spacing(4),
  },
  serialHint: {
    opacity: 0,
    transition: "opacity 0.2s linear;",
    color: theme.palette.primary.main,
    fontSize: 13,
  },
  serialHintActive: {
    opacity: 0.6,
  },
}));

interface IDataInputPageProps {}

export const DataInputPage: FunctionComponent<IDataInputPageProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { name, setName, serialNumber, setSerialNumber, isSerialNumberValid, setIsDataInputSkipped } = useContext(
    AppContext,
  );
  const isNextButtonDisabled = !(isSerialNumberValid && name);

  const onContinue = useCallback(() => {
    history.push(generatePath(ROUTES.PRODUCT.PATH));
  }, [history]);

  const serialNumberClassName = useMemo(() => {
    let hintEnabled = !!(!isSerialNumberValid && serialNumber);
    return `${classes.serialHint} ${hintEnabled ? classes.serialHintActive : ""}`;
  }, [isSerialNumberValid, serialNumber, classes]);

  const onSkip = useCallback(() => {
    setIsDataInputSkipped(true);
    onContinue();
    setIsDialogOpen(false);
  }, [onContinue, setIsDialogOpen, setIsDataInputSkipped]);

  const { setProgress } = useContext(AppContext);
  const { cancelTest } = useContext(HearingProtectionTestContext);
  useEffect(() => {
    setProgress(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cancelTest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Grid item xs container direction="column" justify="space-between">
        <Grid container item alignItems="center">
          <Grid container item xs={12} alignItems="center">
            <PageHeading>{t("data_input.your_information")}</PageHeading>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={12} className={classes.spacingBottom}>
              <Typography variant="body1">{t("data_input.data_message")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.spacingBottom}>
              <TextField
                variant="outlined"
                fullWidth
                label={t("data_input.fields.name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!(!isSerialNumberValid && serialNumber)}
                variant="outlined"
                fullWidth
                label={t("data_input.fields.serial_number")}
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
              />
              <Typography className={serialNumberClassName} variant="body2">
                {t("data_input.fields.serial_number_hint")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <PageActions
            actions={[
              <PrimaryActionButton secondary variant="contained" onClick={() => setIsDialogOpen(true)}>
                {t("data_input.skip_data_entry")}
              </PrimaryActionButton>,
              <PrimaryActionButton disabled={isNextButtonDisabled} variant="contained" onClick={onContinue}>
                {t("data_input.next")}
              </PrimaryActionButton>,
            ]}
          />
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={isDialogOpen}
        heading={t("data_input.skip_input_dialog.heading")}
        content={t("data_input.skip_input_dialog.content")}
        leftActionText={t("data_input.skip_input_dialog.action_1")}
        rightActionText={t("data_input.skip_input_dialog.action_2")}
        rightAction={() => setIsDialogOpen(false)}
        leftAction={onSkip}
      />
    </Fragment>
  );
};
