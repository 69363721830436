import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { PageHeading } from "../../components/typography/page-heading";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { PageActions } from "../../components/containers/page-actions";
import { CONTENT_SPACING_LARGE } from "../../theme/theme";
import { AppContext } from "../../app/context/app-context";
import { VolumeControl } from "../../components/volume/volume-control";
import { generatePath, useHistory } from "react-router";
import { IContexts, ROUTES } from "../../router/router";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { SetVolume } from "../../assets/icons/set-volume";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginTop: CONTENT_SPACING_LARGE,
  },
  underlined: {
    fontWeight: 700,
    textDecoration: "underline",
  },
  systemVolumeImage: {
    width: "50%",
  },
}));

interface IAdjustVolumePageProps {}

export const AdjustVolumeAccessControl = (ctx: IContexts) => {
  const { speakerType } = ctx.hearingProtectionTestContext;
  return !!speakerType;
};

export const AdjustVolumePage: FunctionComponent<IAdjustVolumePageProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [tempVolume, setTempVolume] = useState<number>(-40);
  const { setVolume } = useContext(AppContext);
  const { frequency, resetReadings, speakerType } = useContext(HearingProtectionTestContext);

  const onContinue = useCallback(() => {
    setVolume(-60);
    resetReadings();
    history.push(
      generatePath(ROUTES.HEARING_PROTECTION_TEST.PATH, {
        step: 0,
      }),
    );
  }, [setVolume, history, resetReadings]);

  const { setProgress } = useContext(AppContext);
  useEffect(() => {
    setProgress(90);
  });

  return (
    <Grid item xs container direction="column" justify="space-between">
      <Grid container item alignItems="center">
        <Grid container item xs={12} alignItems="center">
          <PageHeading>{t("adjust_volume.heading")}</PageHeading>
        </Grid>
        <Grid container item xs={12} spacing={6}>
          <Grid item xs={12}>
            <Typography variant="body1">{t(`adjust_volume.content.${speakerType}.content_1`)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans
                i18nKey={`adjust_volume.content.${speakerType}.content_2`}
                components={[<span className={classes.underlined} />]}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.content} container justify="center">
          <VolumeControl volume={tempVolume} onChange={setTempVolume} frequency={frequency!} ear="BOTH" hideSlider />
        </Grid>
        <Grid container item xs={12} spacing={6} className={classes.content} justify={"center"}>
          <SetVolume className={classes.systemVolumeImage} />
        </Grid>
        <Grid container item xs={12} spacing={6} className={classes.content}>
          <Grid item xs={12}>
            <Typography variant="body1">{t(`adjust_volume.content.${speakerType}.content_3`)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PageActions actions={[<PrimaryActionButton onClick={onContinue}>{t("general.next")}</PrimaryActionButton>]} />
      </Grid>
    </Grid>
  );
};
