import React, { FunctionComponent, useMemo } from "react";
import { Theme, makeStyles, Typography, TypographyProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: 20,
    textAlign: "left",
  },
}));

interface IPageHeadingProps extends TypographyProps {}

export const PageHeading: FunctionComponent<IPageHeadingProps> = (props) => {
  const classes = useStyles();
  const { className: classNameProp, ...rest } = props;

  const className = useMemo(() => (classNameProp ? `${classNameProp} ${classes.heading}` : classes.heading), [
    classNameProp,
    classes,
  ]);

  return (
    <Typography variant="h2" className={className} {...rest}>
      {props.children}
    </Typography>
  );
};
