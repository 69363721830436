import { FunctionComponent, useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { IRouteInfo, ROUTES } from "../router/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { AppContext } from "./context/app-context";
import { HearingProtectionTestContext } from "../pages/hearing-protection-test/context/hearing-protection-test-context";

interface IAppProps {}

export const App: FunctionComponent<IAppProps> = (props) => {
  const location = useLocation();
  const contexts = {
    appContext: useContext(AppContext),
    hearingProtectionTestContext: useContext(HearingProtectionTestContext),
  };
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          {Object.values(ROUTES).map((route: IRouteInfo) => {
            let canUserAccessPage = true;
            if (route.ACCESS_CHECK) {
              canUserAccessPage = route.ACCESS_CHECK(contexts);
            }
            if (!canUserAccessPage) {
              if (route.REDIRECT_PATH) {
                return <Redirect path={route.PATH} to={route.REDIRECT_PATH()} key={route.PATH} />;
              }
              return undefined;
            }
            const LayoutComponent = route.LAYOUT;
            const PageComponent = route.COMPONENT;

            return (
              <Route path={route.PATH} key={route.PATH}>
                {LayoutComponent ? (
                  <LayoutComponent {...route.LAYOUT_PROPS}>
                    <PageComponent {...route.PAGE_PROPS} />
                  </LayoutComponent>
                ) : (
                  <PageComponent {...route.PAGE_PROPS} />
                )}
              </Route>
            );
          })}
          <Redirect to={ROUTES.LANDING.PATH} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
