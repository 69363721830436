import _ from "lodash";
import React, { FunctionComponent, useContext, useMemo } from "react";
import { NormalizedTestResult, SpeakerType } from "../../api/neuroth-earwear/graphql/generated";
import { AppContext } from "../../app/context/app-context";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { OverviewDiagram } from "./overview-diagram";

interface IOverviewGraphicProps {
  speakerType: SpeakerType;
  normalizedTestResult: NormalizedTestResult;
}

export const OverviewGraphic: FunctionComponent<IOverviewGraphicProps> = (props) => {
  const { speakerType, normalizedTestResult } = props;

  const { productFilter } = useContext(AppContext);
  const { frequency } = useContext(HearingProtectionTestContext);

  const { min, max, testResultLeft, testResultRight } = useMemo(() => {
    let min = 0;
    let max = 100;
    if (frequency === 500) {
      min = productFilter?.insulationEffectLowerLimit500 || 0;
      max = productFilter?.insulationEffectUpperLimit500 || 100;
    } else {
      min = productFilter?.insulationEffectLowerLimit250 || 0;
      max = productFilter?.insulationEffectUpperLimit250 || 100;
    }

    const diff = max - min;
    const testResultLeft = diff * (normalizedTestResult.normalizedLeftNoiseReduction / 100) + min;
    const testResultRight = diff * (normalizedTestResult.normalizedRightNoiseReduction / 100) + min;

    return {
      min,
      max,
      testResultLeft,
      testResultRight,
    };
  }, [productFilter, normalizedTestResult, frequency]);

  return (
    <OverviewDiagram
      min={_.round(min, 2)}
      max={_.round(max, 2)}
      testResultLeft={_.round(testResultLeft, 2)}
      testResultRight={_.round(testResultRight, 2)}
      showLeftRightSeparately={speakerType === SpeakerType.HEADPHONES}
    />
  );
};
