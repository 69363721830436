import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { PageHeading } from "../../components/typography/page-heading";
import { VolumeControl } from "../../components/volume/volume-control";
import { CONTENT_SPACING_LARGE } from "../../theme/theme";
import { useTranslation } from "react-i18next";
import { HearingProtectionTestContext } from "./context/hearing-protection-test-context";
import { AppContext } from "../../app/context/app-context";
import { Ear, Protection } from "./types/hearing-protection-test-types";
import { HearingProtectionTestGraphicDictionary } from "../../models/hearing-protection-test-graphic-dictionary";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginTop: CONTENT_SPACING_LARGE,
  },
  graphic: {
    width: "100%",
    marginTop: CONTENT_SPACING_LARGE / 3,
    marginBottom: CONTENT_SPACING_LARGE,
  },
  body: {
    fontWeight: 700,
    textDecoration: "underline",
    color: theme.palette.grey["600"],
  },
}));
export interface IHearingProtectionTestReadingProps {
  ear: Ear;
  protection: Protection;
}

export const HearingProtectionTestReading: FunctionComponent<IHearingProtectionTestReadingProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { volume } = useContext(AppContext);
  const { ear, protection } = props;
  const { onChangeReading, frequency, frequencyType } = useContext(HearingProtectionTestContext);

  const [tempVolume, setTempVolume] = useState(volume!);

  const handleVolumeChange = useCallback(
    (newVolume: number) => {
      setTempVolume(newVolume);
      onChangeReading(ear, protection, newVolume);
    },
    [ear, protection, onChangeReading],
  );

  useEffect(() => {
    handleVolumeChange(volume!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Graphic = useMemo(
    () =>
      HearingProtectionTestGraphicDictionary[ear] && HearingProtectionTestGraphicDictionary[ear][protection]
        ? HearingProtectionTestGraphicDictionary[ear][protection]
        : Fragment,
    [ear, protection],
  );

  return (
    <Grid container item alignItems="center" justify="center">
      <Grid container item xs={12} alignItems="center">
        <PageHeading>
          {t(`hearing_protection_test.headings.${props.ear}.${props.protection}.${frequencyType}`)}
        </PageHeading>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container justify="center" xs={12} className={classes.graphic}>
          <Graphic />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" display="inline">
            {t("hearing_protection_test.adjust_volume_prompt_1")}
          </Typography>
          <Typography variant="body1" className={classes.body} display="inline">
            {t(`hearing_protection_test.adjust_volume_prompt_2.${protection}`)}
          </Typography>
          <Typography variant="body1" display="inline">
            {t("hearing_protection_test.adjust_volume_prompt_3")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.content} container justify="center">
        <VolumeControl volume={tempVolume} onChange={handleVolumeChange} frequency={frequency!} ear={props.ear} />
      </Grid>
    </Grid>
  );
};
