import React, { FunctionComponent } from "react";
import { AppBar, makeStyles, Theme, Toolbar, useTheme } from "@material-ui/core";
import { EarWearLogo } from "../../assets/logos/earwear-logo";
import { APP_BAR_HEIGHT } from "../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    flexGrow: 1,
    width: "100%",
    height: APP_BAR_HEIGHT,
    color: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IHeaderProps {}

export const Header: FunctionComponent<IHeaderProps> = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <AppBar position="static" className={classes.logo}>
      <Toolbar variant="dense">
        <EarWearLogo color={theme.palette.background.default} />
      </Toolbar>
    </AppBar>
  );
};
