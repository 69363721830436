import React, { FunctionComponent } from "react";
import { Theme, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: "100%",
  },
  spacingBottom: {
    marginBottom: theme.spacing(5),
  },
}));

interface IPageActionsProps {
  actions: JSX.Element[];
}

export const PageActions: FunctionComponent<IPageActionsProps> = (props) => {
  const classes = useStyles();

  const { actions } = props;

  return (
    <Grid container className={classes.container}>
      {actions.map((action, index) => (
        <Grid
          item
          xs={12}
          key={index}
          className={actions.length > 1 && index + 1 < actions.length ? classes.spacingBottom : ""}
        >
          {action}
        </Grid>
      ))}
    </Grid>
  );
};
