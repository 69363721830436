import React, { createContext, useState } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { i18n } from "../../translations/i18n";

const env = runtimeEnv();

export enum ApolloClientName {
  EARWEAR_API = "EARWEAR_API",
}

interface GraphQLContextType {
  client: ApolloClient<NormalizedCacheObject>;
}

const GraphQLContext = createContext<GraphQLContextType>({} as GraphQLContextType);

export const GraphQLProvider = (props: any) => {
  const value = useGraphQLProvider();

  return (
    <GraphQLContext.Provider value={value}>
      <ApolloProvider client={value.client}>{props.children}</ApolloProvider>
    </GraphQLContext.Provider>
  );
};

const useGraphQLProvider = (): GraphQLContextType => {
  const [client] = useState<ApolloClient<NormalizedCacheObject>>(generateGraphqlClient());

  return { client };
};

const generateGraphqlClient = () => {
  const earWearApiLink = createUploadLink({
    uri: env.REACT_APP_GRAPHQL_URL,
    headers: {
      "accept-language": i18n.language,
    },
  });

  return new ApolloClient({
    link: earWearApiLink,
    cache: new InMemoryCache(),
  });
};
