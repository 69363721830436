import { FC } from "react";
import { BothWithProtection } from "../assets/icons/both-with-protection";
import { BothWithoutProtection } from "../assets/icons/both-without-protection";
import { LeftEarWithProtection } from "../assets/icons/left-ear-with-protection";
import { LeftEarWithoutProtecion } from "../assets/icons/left-ear-without-protection";
import { RightEarWithProtection } from "../assets/icons/right-ear-with-protection";
import { RightEarWithoutProtection } from "../assets/icons/right-ear-without-protection";

export const HearingProtectionTestGraphicDictionary: { [key: string]: { [key: string]: FC } } = {
  BOTH: {
    WITH_PROTECTION: BothWithProtection,
    WITHOUT_PROTECTION: BothWithoutProtection,
  },
  LEFT: {
    WITH_PROTECTION: LeftEarWithProtection,
    WITHOUT_PROTECTION: LeftEarWithoutProtecion,
  },
  RIGHT: {
    WITH_PROTECTION: RightEarWithProtection,
    WITHOUT_PROTECTION: RightEarWithoutProtection,
  },
};
