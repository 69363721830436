import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect } from "react";
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../components/typography/page-heading";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { PageActions } from "../../components/containers/page-actions";
import { AppContext } from "../../app/context/app-context";
import { useHistory } from "react-router";
import { ROUTES } from "../../router/router";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    backgroundImage: (props: { imageUrl: string }) => `url(${props.imageUrl})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "calc(100vh - 583px)",
    maxHeight: "300px",
  },
  underlined: {
    fontWeight: 700,
    textDecoration: "underline",
  },
  graphicPlaceholder: {},
}));

interface IEarwearAccountPageProps {}

export const EarwearAccountPage: FunctionComponent<IEarwearAccountPageProps> = (props) => {
  const classes = useStyles({ imageUrl: "/assets/earwear-account/hero.png" });
  const { t } = useTranslation();
  const history = useHistory();

  const onCreateAccount = useCallback(() => {
    window.open(env.REACT_APP_EARWEAR_ACCOUNT_URL, "_blank");
    history.push(ROUTES.LANDING.PATH);
  }, [history]);
  const onSkip = useCallback(() => {
    history.push(ROUTES.LANDING.PATH);
  }, [history]);

  const { setProgress } = useContext(AppContext);
  useEffect(() => {
    setProgress(100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Grid item xs container direction="column" justify="space-between">
        <Grid container item alignItems="center" spacing={6}>
          <Grid container item xs={12} alignItems="center">
            <PageHeading>{t("earwear_account.heading")}</PageHeading>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={12}>
              <Typography variant="body1">{t("earwear_account.body_1")}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} container justify="center" className={classes.content}></Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item xs={12}>
              <Typography variant="body1">{t("earwear_account.body_2")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <PageActions
            actions={[
              <PrimaryActionButton onClick={onCreateAccount}>{t("earwear_account.action_1")}</PrimaryActionButton>,
              <PrimaryActionButton onClick={onSkip} secondary variant="contained">
                {t("earwear_account.action_2")}
              </PrimaryActionButton>,
            ]}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
