import { createMuiTheme } from "@material-ui/core";
import { common } from "@material-ui/core/colors";
import { isMobile } from "react-device-detect";

export const SPACING_UNIT = 3;

export const PAGE_MAX_WIDTH = 630;
export const CONTENT_SPACING_LARGE = isMobile ? 20 : 30;
export const PAGE_BOTTOM_PADDING = CONTENT_SPACING_LARGE - SPACING_UNIT * 2;
export const CANCEL_BTN_SPACING = 45;
export const BUTTON_HEIGHT = 40;

export const APP_BAR_HEIGHT = isMobile ? 100 : 80;
export const PROGRESS_BAR_HEIGHT = isMobile ? 6 : 8;

export const HEADER_HEIGHT = CANCEL_BTN_SPACING + APP_BAR_HEIGHT + PROGRESS_BAR_HEIGHT;

export const PRIMARY_COLOR = "#000000";
export const SECONDARY_COLOR = "#FBBA00";
export const SECONDARY_LIGHT_COLOR = "#FDDC7F";
export const BLUE = "#6E82ED";
export const RED = "#F07575";
export const GREY200 = "#CCCCCC";
export const GREY400 = "#999999";
export const GREY600 = "#666666";
export const GREY800 = "#333333";

export const theme = createMuiTheme({
  spacing: SPACING_UNIT,
  typography: {
    fontFamily: "Open Sans",
    h3: {
      color: "#666666",
      fontSize: 16,
      fontWeight: 700,
      textAlign: "left",
      textTransform: "uppercase",
    },
    h2: {
      color: "rgba(0,0,0,0.8)",
      fontSize: isMobile ? 22 : 24,
      fontWeight: 700,
    },
    body1: {
      color: "rgba(0,0,0,0.6)",
      fontSize: 16,
    },
    body2: {
      color: "rgba(0,0,0,0.6)",
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
      color: GREY400,
      letterSpacing: 1,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: "#fff",
    },
    secondary: {
      main: SECONDARY_COLOR,
      light: SECONDARY_LIGHT_COLOR,
    },
    text: {
      primary: "#686868",
      secondary: PRIMARY_COLOR,
    },
    background: {
      default: "#FFFFFF",
    },
    grey: {
      // @ts-ignore
      "200": GREY200,
      // @ts-ignore
      "400": GREY400,
      // @ts-ignore
      "600": GREY600,
      // @ts-ignore
      "800": GREY800,
    },
  },
  overrides: {
    MuiFab: {
      root: {
        boxShadow: undefined,
        "&:hover": {
          boxShadow: undefined,
        },
        "&:active": {
          boxShadow: undefined,
        },
      },
    },
    MuiButton: {
      root: {
        padding: SPACING_UNIT * 2,
        borderRadius: SPACING_UNIT * 2,
        fontSize: 14,
        textTransform: "uppercase",
        letterSpacing: 2,
      },
      contained: {
        boxShadow: undefined,
        "&:hover": {
          boxShadow: undefined,
        },
        "&:active": {
          boxShadow: undefined,
        },
      },
    },
    MuiTextField: {
      root: {
        "& label.Mui-focused": {
          color: SECONDARY_COLOR,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: GREY200,
          },
          "&:hover fieldset": {
            borderColor: GREY200,
          },
          "&.Mui-focused fieldset": {
            borderColor: SECONDARY_COLOR,
          },
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: SPACING_UNIT * 3,
        elevation: 0,
      },
    },
    MuiInputLabel: {
      shrink: {
        top: 0,
      },
      formControl: {
        top: -8,
      },
    },
    MuiInput: {
      underline: {
        borderColor: PRIMARY_COLOR,
        "&::after": {
          borderColor: PRIMARY_COLOR,
        },
        "&::before": {
          borderColor: PRIMARY_COLOR,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: PRIMARY_COLOR,
        "&.Mui-checked": {
          color: SECONDARY_COLOR,
        },
      },
    },

    MuiTab: {
      textColorInherit: {
        "&.Mui-selected": {
          backgroundColor: PRIMARY_COLOR,
          color: common.white,
        },
      },
      root: {
        textTransform: "none",
        minHeight: 32,
        borderBottom: "1px solid grey",
        borderTop: "1px solid grey",
        borderRight: "1px solid grey",
      },
    },
  },
});
