import React, { createContext, FunctionComponent, useCallback, useContext, useMemo } from "react";
import { SessionStorageKey } from "../../../models/session-storage-keys";
import { useSessionState } from "../../../hooks/use-session-state";
import { AppContext } from "../../../app/context/app-context";
import {
  NormalizedTestResult,
  SpeakerType,
  ValidationTestReadingsInput,
} from "../../../api/neuroth-earwear/graphql/generated";
import { Ear, Protection } from "../types/hearing-protection-test-types";
import { DateTime } from "luxon";
import lodash from "lodash";

interface IHearingProtectionTestProviderProps {}

export enum HearingProtectionTestFrequencyType {
  HIGH_FQ = "HIGH_FQ",
  LOW_FQ = "LOW_FQ",
}

export interface IHearingProtectionTestContextProps {
  speakerType: SpeakerType | null;
  setSpeakerType: (value: SpeakerType | null) => void;
  readingDictionary: ValidationTestReadingsInput | null;
  onChangeReading: (ear: Ear, protection: Protection, reading: number) => void;
  frequency: number | null;
  setFrequency: (value: number) => void;
  cancelTest: () => void;
  resetReadings: () => void;
  testCreatedAt: DateTime | null;
  setTestCreatedAt: (value: DateTime) => void;
  testId: string | null;
  setTestId: (value: string) => void;
  nextTestAt: string | null;
  setNextTestAt: (value: string) => void;
  normalizedTestResult: NormalizedTestResult | null;
  setNormalizedTestResult: (value: NormalizedTestResult | null) => void;
  areReadingsReady: boolean;
  frequencyType: HearingProtectionTestFrequencyType;

  // successfulTest: boolean;
}

export const HearingProtectionTestContext = createContext<IHearingProtectionTestContextProps>(
  {} as IHearingProtectionTestContextProps,
);

export const HearingProtectionTestContextProvider: FunctionComponent<IHearingProtectionTestProviderProps> = (props) => {
  const [speakerType, setSpeakerType] = useSessionState<SpeakerType | null>(
    SessionStorageKey.HEARING_TEST_CONTEXT_SPEAKER_TYPE,
  );
  const [readingDictionary, setReadingDictionary] = useSessionState<ValidationTestReadingsInput | null>(
    SessionStorageKey.HEARING_TEST_CONTEXT_READINGS,
  );

  const [frequency, setFrequency] = useSessionState<number | null>(SessionStorageKey.HEARING_TEST_CONTEXT_FREQUENCY);

  const [testCreatedAt, setTestCreatedAt] = useSessionState<DateTime | null>(
    SessionStorageKey.HEARING_TEST_SUBMISSION_DATE,
  );

  const [nextTestAt, setNextTestAt] = useSessionState<string | null>(SessionStorageKey.HEARING_TEST_NEXT_TEST_DATE);

  const [normalizedTestResult, setNormalizedTestResult] = useSessionState<NormalizedTestResult | null>(
    SessionStorageKey.HEARING_TEST_CONTEXT_READINGS_NORMALIZED,
  );

  const [testId, setTestId] = useSessionState<string | null>(SessionStorageKey.HEARING_TEST_ID);

  const onChangeReading = useCallback(
    (ear: Ear, protection: Protection, reading: number) => {
      let clone = { ...readingDictionary };
      if (clone[ear] === undefined) {
        clone[ear] = { WITH_PROTECTION: 0, WITHOUT_PROTECTION: 0 };
      }
      clone[ear]![protection] = reading;
      setReadingDictionary(clone);
    },
    [setReadingDictionary, readingDictionary],
  );

  const { purgeSession } = useContext(AppContext);

  const cancelTest = useCallback(() => {
    purgeSession();
    setSpeakerType(null);
    setFrequency(null);
    setTestCreatedAt(null);
    setReadingDictionary({});
    setNormalizedTestResult(null);
  }, [purgeSession, setSpeakerType, setFrequency, setReadingDictionary, setNormalizedTestResult, setTestCreatedAt]);

  const resetReadings = useCallback(() => {
    setReadingDictionary({});
  }, [setReadingDictionary]);

  const areReadingsReady = useMemo(() => {
    if (speakerType === SpeakerType.HEADPHONES) {
      let leftEarReady: boolean = false;
      if (readingDictionary?.LEFT) {
        let { WITHOUT_PROTECTION, WITH_PROTECTION } = readingDictionary.LEFT;
        leftEarReady = lodash.isEmpty(WITHOUT_PROTECTION) && lodash.isEmpty(WITH_PROTECTION);
      }
      let rightEarReady: boolean = false;
      if (readingDictionary?.RIGHT) {
        let { WITHOUT_PROTECTION, WITH_PROTECTION } = readingDictionary.RIGHT;
        rightEarReady = lodash.isEmpty(WITHOUT_PROTECTION) && lodash.isEmpty(WITH_PROTECTION);
      }
      return leftEarReady && rightEarReady;
    }
    if (speakerType === SpeakerType.SPEAKER) {
      if (readingDictionary?.BOTH) {
        let { WITHOUT_PROTECTION, WITH_PROTECTION } = readingDictionary.BOTH;
        return lodash.isEmpty(WITHOUT_PROTECTION) && lodash.isEmpty(WITH_PROTECTION);
      }
    }
    return false;
  }, [readingDictionary, speakerType]);

  const frequencyType = useMemo(
    () =>
      (frequency ? frequency : 0) < 500
        ? HearingProtectionTestFrequencyType.LOW_FQ
        : HearingProtectionTestFrequencyType.HIGH_FQ,
    [frequency],
  );

  return (
    <HearingProtectionTestContext.Provider
      value={{
        speakerType,
        setSpeakerType,
        readingDictionary,
        onChangeReading,
        frequency,
        setFrequency,
        cancelTest,
        resetReadings,
        testCreatedAt,
        setTestCreatedAt,
        testId,
        setTestId,
        nextTestAt,
        setNextTestAt,
        normalizedTestResult,
        setNormalizedTestResult,
        areReadingsReady,
        frequencyType,
      }}
    >
      {props.children}
    </HearingProtectionTestContext.Provider>
  );
};
