import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PageActions } from "../../components/containers/page-actions";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { HearingProtectionTestReading } from "./hearing-protection-test-reading";
import { HearingProtectionTestContext } from "./context/hearing-protection-test-context";
import { HearingProtectionTestPrompt } from "./hearing-protection-test-prompt";
import { generatePath, Redirect, useHistory, useParams } from "react-router";
import { IContexts, ROUTES } from "../../router/router";
import { AppContext } from "../../app/context/app-context";
import { SpeakerType, useValidateTestMutation } from "../../api/neuroth-earwear/graphql/generated";
import { Protection } from "./types/hearing-protection-test-types";
import { DateTime } from "luxon";

interface IHearingProtectionTestProps {
  frequency: number;
}

interface IHearingProtectionTestParams {
  step?: string | undefined;
}

export const HearingProtectionTestAccessControl = (ctx: IContexts) => {
  const { productFamily, productFilter, productVariant } = ctx.appContext;
  const { speakerType } = ctx.hearingProtectionTestContext;
  return !!(productFamily && productFilter && productVariant && speakerType);
};

export const HearingProtectionTestPage: FunctionComponent<IHearingProtectionTestProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<IHearingProtectionTestParams>();
  let currentStep: number = -1;

  if (!isNaN(parseInt(params.step as string))) {
    currentStep = parseInt(params.step as string);
  }
  const {
    speakerType,
    frequency,
    setFrequency,
    readingDictionary,
    setTestCreatedAt,
    setNextTestAt,
    setTestId,
    resetReadings,
    setNormalizedTestResult,
  } = useContext(HearingProtectionTestContext);
  const { name, serialNumber, productFilter } = useContext(AppContext);

  const reverseProtectionOrder = useMemo(() => frequency === 250, [frequency]);
  const firstStepsProtection: Protection = useMemo(
    () => (reverseProtectionOrder ? "WITH_PROTECTION" : "WITHOUT_PROTECTION"),
    [reverseProtectionOrder],
  );
  const secondStepsProtection: Protection = useMemo(
    () => (reverseProtectionOrder ? "WITHOUT_PROTECTION" : "WITH_PROTECTION"),
    [reverseProtectionOrder],
  );

  const promptType = reverseProtectionOrder ? "REMOVE_PROTECTION" : "WEAR_PROTECTION";
  const steps = useMemo(() => {
    return speakerType === SpeakerType.HEADPHONES
      ? [
          <HearingProtectionTestReading ear="LEFT" protection={firstStepsProtection} />,
          <HearingProtectionTestReading ear="RIGHT" protection={firstStepsProtection} />,
          <HearingProtectionTestPrompt type={promptType} />,
          <HearingProtectionTestReading ear="LEFT" protection={secondStepsProtection} />,
          <HearingProtectionTestReading ear="RIGHT" protection={secondStepsProtection} />,
        ]
      : speakerType === SpeakerType.SPEAKER
      ? [
          <HearingProtectionTestReading ear="BOTH" protection={firstStepsProtection} />,
          <HearingProtectionTestPrompt type={promptType} />,
          <HearingProtectionTestReading ear="BOTH" protection={secondStepsProtection} />,
        ]
      : [];
  }, [speakerType, firstStepsProtection, promptType, secondStepsProtection]);

  const [validateTestMutation, { loading: validationLoading }] = useValidateTestMutation({
    onCompleted: (data) => {
      const { testResponse, testValidation } = data.validateTestReport;
      if (testValidation) {
        if (testResponse) {
          const { test, normalized } = testResponse;
          setTestCreatedAt(DateTime.fromJSDate(new Date(test?.createdAt)) || null);
          setTestId(test?.id! || "");
          setNextTestAt(test?.nextTestAt || null);
          setNormalizedTestResult(normalized! || null);
        }
        history.push(generatePath(ROUTES.OVERVIEW.PATH));
      } else if (frequency === 500) {
        resetReadings();
        setFrequency(250);
        history.push(
          generatePath(ROUTES.HEARING_PROTECTION_TEST.PATH, {
            step: 0,
          }),
        );
      } else {
        history.push(generatePath(ROUTES.TEST_FAILURE.PATH));
      }
    },
    onError: console.error,
  });

  const testValidation = useCallback(async () => {
    await validateTestMutation({
      variables: {
        testInfo: {
          frequency: frequency!,
          fullName: name,
          serialNumber: serialNumber,
          speakerType: speakerType!,
          productFilterId: productFilter?.id!,
          readings: readingDictionary!,
        },
      },
    });
  }, [frequency, name, serialNumber, speakerType, productFilter, readingDictionary, validateTestMutation]);

  const onContinue = useCallback(async () => {
    if (currentStep < steps.length - 1) {
      history.push(
        generatePath(ROUTES.HEARING_PROTECTION_TEST.PATH, {
          step: currentStep + 1,
        }),
      );
    } else {
      await testValidation();
    }
  }, [currentStep, history, steps, testValidation]);

  const readingsOrder = useMemo(() => {
    return speakerType === SpeakerType.HEADPHONES
      ? [
          Number.isFinite(readingDictionary?.LEFT ? readingDictionary?.LEFT[firstStepsProtection] : NaN),
          Number.isFinite(readingDictionary?.RIGHT ? readingDictionary?.RIGHT[firstStepsProtection] : NaN),
          true,
          Number.isFinite(readingDictionary?.LEFT ? readingDictionary?.LEFT[secondStepsProtection] : NaN),
          Number.isFinite(readingDictionary?.RIGHT ? readingDictionary?.RIGHT[secondStepsProtection] : NaN),
        ]
      : [
          Number.isFinite(readingDictionary?.BOTH ? readingDictionary?.BOTH[firstStepsProtection] : NaN),
          true,
          Number.isFinite(readingDictionary?.BOTH ? readingDictionary?.BOTH[secondStepsProtection] : NaN),
        ];
  }, [readingDictionary, speakerType, firstStepsProtection, secondStepsProtection]);

  const previousReadingsCompleted = useMemo(() => {
    return readingsOrder.reduce((previousValue, currentValue, index) => {
      return (index < currentStep ? currentValue : true) && previousValue;
    }, true);
  }, [currentStep, readingsOrder]);

  const isStepInvalid = useMemo(() => currentStep + 1 > steps.length || currentStep < 0 || !previousReadingsCompleted, [
    currentStep,
    steps,
    previousReadingsCompleted,
  ]);

  const { setProgress } = useContext(AppContext);
  useEffect(() => {
    if (!isStepInvalid) {
      let percentage = (currentStep / steps.length) * 40;
      setProgress(45 + percentage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  if (isStepInvalid) {
    let redirectTo = generatePath(ROUTES.HEARING_PROTECTION_TEST.PATH, {
      step: currentStep - 1,
    });
    return <Redirect to={redirectTo} />;
  }

  return (
    <Grid item xs container direction="column" justify="space-between">
      {steps[currentStep]}
      <Grid item>
        <Box mt={15}>
          <PageActions
            actions={[
              <PrimaryActionButton onClick={onContinue} disabled={validationLoading}>
                {validationLoading ? <CircularProgress size="23px" color="secondary" /> : t("general.next")}
              </PrimaryActionButton>,
            ]}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
