import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import { ChangeEvent, Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ProductResponse,
  ProductVariantFilterResponse,
  ProductVariantResponse,
  useProductsQuery,
} from "../../api/neuroth-earwear/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  radioButtons: {
    width: "100%",
    marginTop: theme.spacing(5),
  },
  radioBoxLabel: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
}));

interface IProductSelectorProps {
  productFamily: ProductResponse | null;
  productVariant: ProductVariantResponse | null;
  productVariantFilter: ProductVariantFilterResponse | null;
  onProductFamilyChange: (value: ProductResponse | null) => void;
  onProductVariantChange: (value: ProductVariantResponse | null) => void;
  onProductVariantFilterChange: (value: ProductVariantFilterResponse | null) => void;
}

export const ProductSelector: FunctionComponent<IProductSelectorProps> = (props: IProductSelectorProps) => {
  const {
    productVariant,
    productVariantFilter,
    onProductFamilyChange,
    onProductVariantChange,
    onProductVariantFilterChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [productFamilies, setProductFamilies] = useState<ProductResponse[]>([]);
  const variants = useMemo(() => productFamilies.flatMap((productFamily) => productFamily.variants), [productFamilies]);

  useProductsQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setProductFamilies(data.products as ProductResponse[]);
    },
    onError: console.error,
  });

  const isVariantSelected = useMemo(() => productVariant !== null, [productVariant]);

  const setProductVariantWithId = useCallback(
    (id: string) => {
      const foundVariant = variants.find((variant: ProductVariantResponse) => variant.id === id);
      const productFamily = productFamilies.find((pf) => pf.variants.map((v) => v.id).includes(id));
      if (productFamily && foundVariant) {
        if (foundVariant.filters) {
          onProductVariantFilterChange(foundVariant.filters[0]);
        }
        onProductFamilyChange(productFamily);
        onProductVariantChange(foundVariant);
      }
    },
    [productFamilies, variants, onProductVariantFilterChange, onProductFamilyChange, onProductVariantChange],
  );

  const handleProductVariant = (event: ChangeEvent<{ value: string }>) => {
    const id = event.target.value;
    setProductVariantWithId(id);
  };
  const handleProductVariantFilter = (event: ChangeEvent<{ value: string }>) => {
    const id = event.target.value;
    const foundFilter = productVariant?.filters.find((filter: ProductVariantFilterResponse) => filter.id === id);
    if (foundFilter) {
      onProductVariantFilterChange(foundFilter);
    }
  };

  useEffect(() => {
    if (productFamilies && productFamilies[0]?.variants) {
      const variant = productFamilies[0]?.variants[0] as ProductVariantResponse;
      setProductVariantWithId(variant.id);
    }
  }, [productFamilies, setProductVariantWithId]);

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="center" mt={3}>
            <FormControl className={classes.radioButtons}>
              <FormLabel id="product-variant-label" className={classes.radioBoxLabel}>
                {t("product.product_variant")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="product-variant-label"
                name="product-variant"
                value={productVariant ? productVariant.id : ""}
                onChange={handleProductVariant}
              >
                {variants.map((variant: ProductVariantResponse) => (
                  <FormControlLabel key={variant.id} value={variant.id} control={<Radio />} label={variant.name} />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="center" mt={3}>
            <FormControl className={classes.radioButtons}>
              <FormLabel id="product-filter-label" className={classes.radioBoxLabel}>
                {t("product.product_filter")}
              </FormLabel>
              <RadioGroup
                aria-labelledby="product-filter-label"
                name="product-filter"
                value={productVariantFilter ? productVariantFilter.id : ""}
                onChange={handleProductVariantFilter}
              >
                {isVariantSelected &&
                  productVariant?.filters.map((filter) => (
                    <FormControlLabel key={filter.id} value={filter.id} control={<Radio />} label={filter.name} />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
