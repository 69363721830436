import React, { FunctionComponent, useCallback, useContext, useMemo } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { PageHeading } from "../../components/typography/page-heading";
import { useTranslation } from "react-i18next";
import { PageActions } from "../../components/containers/page-actions";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { BUTTON_HEIGHT, CONTENT_SPACING_LARGE, HEADER_HEIGHT } from "../../theme/theme";
import { generatePath, useHistory } from "react-router";
import { ROUTES } from "../../router/router";

import { isMobile } from "react-device-detect";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";

const useStyles = makeStyles((theme: Theme) => ({
  spacingTop: {
    marginTop: theme.spacing(8),
  },
  container: {
    height: `calc(100vh - ${HEADER_HEIGHT + CONTENT_SPACING_LARGE + BUTTON_HEIGHT * 2}px - ${theme.spacing(
      isMobile ? 30 : 16,
    )}px)`,
    overflowY: "auto",
    overflowX: "hidden",
  },
  date: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: "36px",
  },
  dateContainer: {
    marginTop: theme.spacing(10),
  },
}));

interface ISecurityInformationProps {}

export const SecurityInformationPage: FunctionComponent<ISecurityInformationProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { nextTestAt } = useContext(HearingProtectionTestContext);

  const onContinue = useCallback(() => {
    history.push({
      pathname: generatePath(ROUTES.SUMMARY.PATH),
    });
  }, [history]);

  const dateLabel = useMemo(
    () =>
      !!nextTestAt
        ? new Date(nextTestAt).toLocaleDateString("de-AU", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : "-",
    [nextTestAt],
  );

  return (
    <Grid item container direction="column" justify="space-between">
      <Grid item className={classes.container}>
        <Grid item container alignItems="center">
          <Grid item container xs={12} alignItems="center">
            <PageHeading>{t("security_information.heading")}</PageHeading>
          </Grid>
          <Grid item container xs={12} spacing={6}>
            <Grid item xs={12}>
              <Typography variant="body1">{t("security_information.wear_headphones")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t("security_information.warning")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t("security_information.how_to_store")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t("security_information.clean")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t("security_information.read_instructions")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t("security_information.next_test")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.dateContainer}>
          <Grid item xs={12}>
            <Typography className={classes.date}>{dateLabel}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <PageActions
          actions={[<PrimaryActionButton onClick={onContinue}>{t("security_information.button")}</PrimaryActionButton>]}
        />
      </Grid>
    </Grid>
  );
};
