import { FunctionComponent } from "react";
import { LinearProgress, makeStyles, Theme } from "@material-ui/core";
import { PROGRESS_BAR_HEIGHT } from "../../theme/theme";

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    height: PROGRESS_BAR_HEIGHT,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey["200"],
  },
  barColorPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface IStepperProps {
  progress: number;
}

export const Progress: FunctionComponent<IStepperProps> = (props: any) => {
  const classes = useStyles();

  return (
    <LinearProgress
      variant="determinate"
      className={classes.bar}
      classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
      value={props.progress}
    />
  );
};
