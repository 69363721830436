import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { PageHeading } from "../../components/typography/page-heading";
import { useTranslation } from "react-i18next";
import { PageActions } from "../../components/containers/page-actions";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { generatePath, useHistory } from "react-router";
import { IContexts, ROUTES } from "../../router/router";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { AppContext } from "../../app/context/app-context";
import { ConfirmationDialog } from "../../components/confirmation-dialog/confirmation-dialog";
import { SpeakerType } from "../../api/neuroth-earwear/graphql/generated";

interface ITestFailureProps {}

export const TestFailureAccessControl = (ctx: IContexts) => {
  const { readingDictionary, speakerType } = ctx.hearingProtectionTestContext;

  let readingsComplete = false;

  if (speakerType === SpeakerType.HEADPHONES) {
    readingsComplete =
      Number.isFinite(readingDictionary?.LEFT?.WITHOUT_PROTECTION) &&
      Number.isFinite(readingDictionary?.RIGHT?.WITHOUT_PROTECTION) &&
      Number.isFinite(readingDictionary?.LEFT?.WITH_PROTECTION) &&
      Number.isFinite(readingDictionary?.RIGHT?.WITH_PROTECTION);
  } else if (speakerType === SpeakerType.SPEAKER) {
    readingsComplete =
      Number.isFinite(readingDictionary?.BOTH?.WITHOUT_PROTECTION) &&
      Number.isFinite(readingDictionary?.BOTH?.WITH_PROTECTION);
  }
  return readingsComplete;
};

export const TestFailurePage: FunctionComponent<ITestFailureProps> = (props) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();

  const { setFrequency, cancelTest } = useContext(HearingProtectionTestContext);

  const endTest = useCallback(() => {
    cancelTest();
    setFrequency(500);
    history.push(
      generatePath(ROUTES.LANDING.PATH, {
        step: 0,
      }),
    );
  }, [history, cancelTest, setFrequency]);

  const { setProgress, isDataInputSkipped } = useContext(AppContext);

  useEffect(() => {
    setProgress(85);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContactSupportBtn = useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);

  const handleCloseModal = useCallback(() => {
    setIsDialogOpen(false);
    history.push(generatePath(ROUTES.LANDING.PATH));
  }, [setIsDialogOpen, history]);

  return (
    <Grid item xs container direction="column" justify="space-between">
      <Grid container item alignItems="center">
        <Grid container item xs={12} alignItems="center">
          <PageHeading>{t("test_failure.heading")}</PageHeading>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t("test_failure.body_1")}</Typography>
          <br />
          <br />
          <Typography variant="body1">{t("test_failure.body_2")}</Typography>
        </Grid>
      </Grid>

      <Grid item>
        <PageActions
          actions={[
            !isDataInputSkipped ? (
              <PrimaryActionButton secondary variant="contained" onClick={handleContactSupportBtn}>
                {t("test_failure.contact_customer_support")}
              </PrimaryActionButton>
            ) : (
              <Fragment />
            ),
            <PrimaryActionButton variant="contained" onClick={endTest}>
              {t("test_failure.retake_test")}
            </PrimaryActionButton>,
          ]}
        />
      </Grid>

      <ConfirmationDialog
        open={isDialogOpen}
        content={t("test_failure.dialog_message")}
        rightActionText={t("test_failure.dialog_btn")}
        rightAction={handleCloseModal}
      />
    </Grid>
  );
};
