import { MuiThemeProvider } from "@material-ui/core";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { GraphQLProvider } from "./api/context/graphql-context";
import { App } from "./app/app";
import { AppContextProvider } from "./app/context/app-context";
import { HttpsRedirect } from "./app/https-redirect";
import { HearingProtectionTestContextProvider } from "./pages/hearing-protection-test/context/hearing-protection-test-context";
import { theme } from "./theme/theme";
import { i18n } from "./translations/i18n";

ReactDOM.render(
  <HttpsRedirect disabled={false}>
    <BrowserRouter>
      <GraphQLProvider>
        <MuiThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <AppContextProvider>
              <HearingProtectionTestContextProvider>
                <App />
              </HearingProtectionTestContextProvider>
            </AppContextProvider>
          </I18nextProvider>
        </MuiThemeProvider>
      </GraphQLProvider>
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById("root"),
);
