import React, { FunctionComponent, Fragment, useCallback, useMemo, useState, useEffect } from "react";
import { Theme, makeStyles, Grid, Fab, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PauseRounded, PlayArrowRounded } from "@material-ui/icons";
import { VolumeSlider } from "./volume-slider";
import * as Tone from "tone";
import { CONTENT_SPACING_LARGE } from "../../theme/theme";
import { Ear } from "../../pages/hearing-protection-test/types/hearing-protection-test-types";

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    width: 74,
    height: 74,
    [theme.breakpoints.down("xs")]: {
      width: 65,
      height: 65,
    },
    marginBottom: theme.spacing(2),
  },
  fabIcon: {
    width: 45,
    height: 45,
  },
  startNowText: {
    fontWeight: 700,
    color: theme.palette.grey["800"],
  },
  spacingTop: {
    marginTop: CONTENT_SPACING_LARGE / 3,
  },
}));

interface IVolumeControlProps {
  volume: number;
  onChange: (value: number) => void;
  frequency: number;
  ear: Ear;
  hideSlider?: boolean;
}

export const VolumeControl: FunctionComponent<IVolumeControlProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [playing, setPlaying] = useState<boolean>(false);
  const { volume, onChange, frequency, hideSlider, ear } = props;

  const audioPlayer = useMemo(() => {
    const _osc = new Tone.Merge().toDestination();
    const leftEar = new Tone.Oscillator();
    const rightEar = new Tone.Oscillator();
    leftEar.frequency.value = 500;
    leftEar.volume.value = -40;
    leftEar.connect(_osc, 0, 0);
    rightEar.frequency.value = 500;
    rightEar.volume.value = -40;
    rightEar.connect(_osc, 0, 1);
    return {
      osc: _osc,
      leftEar: leftEar,
      rightEar: rightEar,
    };
  }, []);

  const play = useCallback(() => {
    if (playing) {
      audioPlayer.leftEar.stop();
      audioPlayer.rightEar.stop();
    } else {
      if (ear === "LEFT" || ear === "BOTH") audioPlayer.leftEar.start();
      if (ear === "RIGHT" || ear === "BOTH") audioPlayer.rightEar.start();
    }
    setPlaying(!playing);
  }, [playing, setPlaying, audioPlayer, ear]);

  const onChangeVolume = useCallback(
    (_value: number) => {
      onChange(_value);
    },
    [onChange],
  );

  useEffect(() => {
    audioPlayer.leftEar.volume.value = !isNaN(volume) ? volume : -40;
    audioPlayer.leftEar.frequency.value = !isNaN(frequency) ? frequency : -40;
    audioPlayer.rightEar.volume.value = !isNaN(volume) ? volume : -40;
    audioPlayer.rightEar.frequency.value = !isNaN(frequency) ? frequency : -40;
  }, [volume, frequency, audioPlayer]);

  useEffect(() => {
    return () => {
      audioPlayer.leftEar.stop();
      audioPlayer.rightEar.stop();
    };
  }, [audioPlayer]);

  return (
    <Fragment>
      <Grid item xs={12}>
        <Grid container direction="column" alignItems="center">
          <Fab color="primary" className={classes.fab} onClick={play}>
            {!playing && <PlayArrowRounded className={classes.fabIcon} />}
            {playing && <PauseRounded className={classes.fabIcon} />}
          </Fab>
          <Typography className={classes.startNowText} variant="body1">
            {t("adjust_volume.start_tone")}
          </Typography>
        </Grid>
      </Grid>
      {!hideSlider && (
        <Grid item xs={12} className={classes.spacingTop}>
          <VolumeSlider volume={volume} onChange={onChangeVolume} />
        </Grid>
      )}
    </Fragment>
  );
};
