import React, { FunctionComponent } from "react";
import { Theme, makeStyles, DialogTitleProps, DialogTitle, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
}));

interface IResponsiveDialogTitleProps extends DialogTitleProps {}

export const ResponsiveDialogTitle: FunctionComponent<IResponsiveDialogTitleProps> = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const cn = className ? `${classes.dialogTitle} ${className}` : classes.dialogTitle;
  return (
    <DialogTitle {...rest} className={cn}>
      <Typography variant={"h2"}>{children}</Typography>
    </DialogTitle>
  );
};
