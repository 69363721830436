import React, { FunctionComponent, useMemo } from "react";
import { Theme, makeStyles, Button, ButtonProps } from "@material-ui/core";
import { BUTTON_HEIGHT } from "../../theme/theme";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: BUTTON_HEIGHT,
    width: "100%",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: isMobile ? `${theme.palette.primary.main} !important` : ``,
    },
  },
  primary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    padding: theme.spacing(2) - 1,
  },
}));

interface IPrimaryActionButtonProps extends ButtonProps {
  secondary?: boolean;
}

export const PrimaryActionButton: FunctionComponent<IPrimaryActionButtonProps> = (props) => {
  const classes = useStyles();
  const { secondary, children, ...rest } = props;

  const className = useMemo(
    () => (secondary ? `${classes.button} ${classes.secondary}` : `${classes.button} ${classes.primary}`),
    [classes, secondary],
  );

  return (
    <Button
      key={`button-${secondary ? "secondary" : "primary"}-${props.disabled ? "disabled" : "enabled"}-${props.key}`}
      variant="contained"
      className={className}
      {...rest}
    >
      {children}
    </Button>
  );
};
