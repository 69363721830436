import React, { FunctionComponent, useMemo } from "react";
import { Theme, makeStyles, Grid, Typography, useTheme } from "@material-ui/core";
import { BLUE, RED } from "../../theme/theme";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const SIZE = 25;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.secondary.main,
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  labelLeftRight: {
    color: theme.palette.primary.contrastText,
  },
  labelContainer: {},
  labelContainerLeftRight: {
    backgroundColor: (props: any) => props.color,
    borderRadius: 90,
    width: SIZE,
    height: SIZE,
  },
  container: {
    marginBottom: (props) => (props.up ? 40 : -70),
  },
  value: {
    color: (props: any) => props.color,
    marginLeft: 60,
  },
  valueLeftRight: {
    color: (props: any) => props.color,
    marginLeft: 40,
  },
  icon: {
    color: (props: any) => props.color,
    transform: ({ up, showLeftRightSeparately }: any) =>
      showLeftRightSeparately ? `translateY(${up ? "10px" : "-10px"})` : "",
  },
  span: {
    "&:active": {
      boxShadow: "0 0 0 0 transparent",
    },
    "&:hover": {
      boxShadow: "0 0 0 0 transparent",
    },
    "&:focus": {
      boxShadow: "0 0 0 0 transparent",
    },
    boxShadow: "0 0 0 0 transparent",
  },
  legendContainer: {
    transform: "translateX(27px)",
  },
}));

export const OverviewDiagramThumb: FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  const { className, showLeftRightSeparately } = props;
  const value = useMemo(() => (showLeftRightSeparately ? `${props["aria-valuenow"]}dB` : ""), [
    props,
    showLeftRightSeparately,
  ]);
  const index = useMemo(() => props["data-index"], [props]);
  const up = useMemo(() => index === 0, [index]);
  const theme = useTheme();
  const { color, label } = useMemo(() => {
    if (!showLeftRightSeparately) {
      return {
        color: theme.palette.secondary.main,
        label: t("overview.diagram.your_result"),
      };
    }

    if (index === 0) {
      return {
        color: BLUE,
        label: t("overview.diagram.l"),
      };
    }
    return {
      color: RED,
      label: t("overview.diagram.r"),
    };
  }, [index, showLeftRightSeparately, t, theme]);

  const classes = useStyles({
    color,
    up,
    showLeftRightSeparately,
  });

  const cn = useMemo(() => `${className} ${classes.span}`, [className, classes]);

  return (
    <span {...props} className={cn}>
      <Grid container className={classes.container} justify="center" alignItems={up ? "flex-start" : "flex-end"}>
        <Grid item xs>
          <Grid container justify="center">
            {!up && (
              <Grid item>
                <ArrowDropUp className={classes.icon} fontSize="small" />
              </Grid>
            )}
            <Grid item>
              <Grid
                container
                alignContent="flex-start"
                className={showLeftRightSeparately ? classes.legendContainer : ""}
              >
                <Grid item xs={1}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={showLeftRightSeparately ? classes.labelContainerLeftRight : classes.labelContainer}
                  >
                    <Typography className={showLeftRightSeparately ? classes.labelLeftRight : classes.label}>
                      {label}
                    </Typography>
                    {!showLeftRightSeparately && (
                      <Typography className={classes.label}>{`(${props["aria-valuenow"]} dB)`}</Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <Typography className={showLeftRightSeparately ? classes.valueLeftRight : classes.value}>
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {up && (
              <Grid item>
                <ArrowDropDown className={classes.icon} fontSize="small" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
};
