import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type CertificatePdfResponse = {
  __typename?: 'CertificatePdfResponse';
  base64: Scalars['String'];
};

export enum CreateTestReportError {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type CreateTestReportInput = {
  frequency: Scalars['Float'];
  fullName: Scalars['String'];
  productFilterId: Scalars['ID'];
  serialNumber: Scalars['String'];
  testResult: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  speakerType: Scalars['String'];
  nextTestAt: Scalars['DateTime'];
  leftNoiseReduction: Scalars['Float'];
  rightNoiseReduction: Scalars['Float'];
};


export type DummyEnumResponse = {
  __typename?: 'DummyEnumResponse';
  createTestReportError: CreateTestReportError;
};

export type Mutation = {
  __typename?: 'Mutation';
  createTestReport: TestReport;
  updateTestReport: Scalars['String'];
  validateTestReport: ValidateTestResponse;
  sendCertificateToUser: Scalars['String'];
  sendCertificateToAdmin: Scalars['String'];
};


export type MutationCreateTestReportArgs = {
  test_report: CreateTestReportInput;
};


export type MutationUpdateTestReportArgs = {
  email: Scalars['String'];
  frequency: Scalars['Float'];
  fullName: Scalars['String'];
  productFilterId: Scalars['ID'];
  serialNumber: Scalars['String'];
  testResult: Scalars['Boolean'];
  testReportId: Scalars['ID'];
};


export type MutationValidateTestReportArgs = {
  testInfo: ValidationTestReportInput;
};


export type MutationSendCertificateToUserArgs = {
  sendCertificateUserInput: SendCertificateUserInput;
};


export type MutationSendCertificateToAdminArgs = {
  testId: Scalars['String'];
};

export type NormalizedTestResult = {
  __typename?: 'NormalizedTestResult';
  normalizedLeftNoiseReduction: Scalars['Float'];
  normalizedRightNoiseReduction: Scalars['Float'];
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  variants: Array<ProductVariantResponse>;
};

export type ProductVariantFilterResponse = {
  __typename?: 'ProductVariantFilterResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  variantId: Scalars['String'];
  variant: ProductVariantResponse;
  insulationEffectLowerLimit250: Scalars['Float'];
  insulationEffectUpperLimit250: Scalars['Float'];
  insulationEffectLowerLimit500: Scalars['Float'];
  insulationEffectUpperLimit500: Scalars['Float'];
};

export type ProductVariantResponse = {
  __typename?: 'ProductVariantResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
  productId: Scalars['String'];
  product: ProductResponse;
  filters: Array<ProductVariantFilterResponse>;
};

export type Query = {
  __typename?: 'Query';
  dummyEnumQuery?: Maybe<DummyEnumResponse>;
  testReports: Array<TestReport>;
  testReportById: TestReport;
  products: Array<ProductResponse>;
  productById: ProductResponse;
  productVariants: Array<ProductVariantResponse>;
  productVariantById: ProductVariantResponse;
  productVariantFilters: Array<ProductVariantFilterResponse>;
  productVariantFilterById: ProductVariantFilterResponse;
  certificatePdf: CertificatePdfResponse;
};


export type QueryTestReportByIdArgs = {
  id: Scalars['ID'];
};


export type QueryProductByIdArgs = {
  id: Scalars['ID'];
};


export type QueryProductVariantByIdArgs = {
  id: Scalars['ID'];
};


export type QueryProductVariantFilterByIdArgs = {
  id: Scalars['ID'];
};


export type QueryCertificatePdfArgs = {
  testId: Scalars['ID'];
};

export type SendCertificateUserInput = {
  receiver: Scalars['String'];
  testId: Scalars['String'];
};

export enum SpeakerType {
  SPEAKER = 'SPEAKER',
  HEADPHONES = 'HEADPHONES'
}

export type TestReport = {
  __typename?: 'TestReport';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  frequency: Scalars['Float'];
  fullName: Scalars['String'];
  productFilterId: Scalars['ID'];
  productFilter: ProductVariantFilterResponse;
  serialNumber: Scalars['String'];
  testResult: Scalars['Boolean'];
  leftNoiseReduction: Scalars['Float'];
  rightNoiseReduction: Scalars['Float'];
  nextTestAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  speakerType: SpeakerType;
};

export type TestResponse = {
  __typename?: 'TestResponse';
  test?: Maybe<TestReport>;
  normalized?: Maybe<NormalizedTestResult>;
};

export type ValidateTestResponse = {
  __typename?: 'ValidateTestResponse';
  testValidation: Scalars['Boolean'];
  testResponse: TestResponse;
};

export type ValidationTestReadingEarInput = {
  WITH_PROTECTION: Scalars['Float'];
  WITHOUT_PROTECTION: Scalars['Float'];
};

export type ValidationTestReadingsInput = {
  BOTH?: Maybe<ValidationTestReadingEarInput>;
  LEFT?: Maybe<ValidationTestReadingEarInput>;
  RIGHT?: Maybe<ValidationTestReadingEarInput>;
};

export type ValidationTestReportInput = {
  speakerType?: Maybe<SpeakerType>;
  fullName?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  frequency: Scalars['Float'];
  productFilterId: Scalars['String'];
  readings: ValidationTestReadingsInput;
};

export type SendCertificateToUserMutationVariables = Exact<{
  info: SendCertificateUserInput;
  testId: Scalars['String'];
}>;


export type SendCertificateToUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendCertificateToUser' | 'sendCertificateToAdmin'>
);

export type ValidateTestMutationVariables = Exact<{
  testInfo: ValidationTestReportInput;
}>;


export type ValidateTestMutation = (
  { __typename?: 'Mutation' }
  & { validateTestReport: (
    { __typename?: 'ValidateTestResponse' }
    & Pick<ValidateTestResponse, 'testValidation'>
    & { testResponse: (
      { __typename?: 'TestResponse' }
      & { test?: Maybe<(
        { __typename?: 'TestReport' }
        & Pick<TestReport, 'id' | 'fullName' | 'createdAt' | 'nextTestAt'>
      )>, normalized?: Maybe<(
        { __typename?: 'NormalizedTestResult' }
        & Pick<NormalizedTestResult, 'normalizedLeftNoiseReduction' | 'normalizedRightNoiseReduction'>
      )> }
    ) }
  ) }
);

export type CertificatePdfQueryVariables = Exact<{
  testId: Scalars['ID'];
}>;


export type CertificatePdfQuery = (
  { __typename?: 'Query' }
  & { certificatePdf: (
    { __typename?: 'CertificatePdfResponse' }
    & Pick<CertificatePdfResponse, 'base64'>
  ) }
);

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'ProductResponse' }
    & Pick<ProductResponse, 'id' | 'name'>
    & { variants: Array<(
      { __typename?: 'ProductVariantResponse' }
      & Pick<ProductVariantResponse, 'id' | 'name'>
      & { filters: Array<(
        { __typename?: 'ProductVariantFilterResponse' }
        & Pick<ProductVariantFilterResponse, 'id' | 'name' | 'insulationEffectLowerLimit250' | 'insulationEffectLowerLimit500' | 'insulationEffectUpperLimit250' | 'insulationEffectUpperLimit500'>
      )> }
    )> }
  )> }
);


export const SendCertificateToUserDocument = gql`
    mutation sendCertificateToUser($info: SendCertificateUserInput!, $testId: String!) {
  sendCertificateToUser(sendCertificateUserInput: $info)
  sendCertificateToAdmin(testId: $testId)
}
    `;
export type SendCertificateToUserMutationFn = Apollo.MutationFunction<SendCertificateToUserMutation, SendCertificateToUserMutationVariables>;

/**
 * __useSendCertificateToUserMutation__
 *
 * To run a mutation, you first call `useSendCertificateToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCertificateToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCertificateToUserMutation, { data, loading, error }] = useSendCertificateToUserMutation({
 *   variables: {
 *      info: // value for 'info'
 *      testId: // value for 'testId'
 *   },
 * });
 */
export function useSendCertificateToUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendCertificateToUserMutation, SendCertificateToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendCertificateToUserMutation, SendCertificateToUserMutationVariables>(SendCertificateToUserDocument, options);
      }
export type SendCertificateToUserMutationHookResult = ReturnType<typeof useSendCertificateToUserMutation>;
export type SendCertificateToUserMutationResult = Apollo.MutationResult<SendCertificateToUserMutation>;
export type SendCertificateToUserMutationOptions = Apollo.BaseMutationOptions<SendCertificateToUserMutation, SendCertificateToUserMutationVariables>;
export const ValidateTestDocument = gql`
    mutation validateTest($testInfo: ValidationTestReportInput!) {
  validateTestReport(testInfo: $testInfo) {
    testValidation
    testResponse {
      test {
        id
        fullName
        createdAt
        nextTestAt
      }
      normalized {
        normalizedLeftNoiseReduction
        normalizedRightNoiseReduction
      }
    }
  }
}
    `;
export type ValidateTestMutationFn = Apollo.MutationFunction<ValidateTestMutation, ValidateTestMutationVariables>;

/**
 * __useValidateTestMutation__
 *
 * To run a mutation, you first call `useValidateTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTestMutation, { data, loading, error }] = useValidateTestMutation({
 *   variables: {
 *      testInfo: // value for 'testInfo'
 *   },
 * });
 */
export function useValidateTestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateTestMutation, ValidateTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ValidateTestMutation, ValidateTestMutationVariables>(ValidateTestDocument, options);
      }
export type ValidateTestMutationHookResult = ReturnType<typeof useValidateTestMutation>;
export type ValidateTestMutationResult = Apollo.MutationResult<ValidateTestMutation>;
export type ValidateTestMutationOptions = Apollo.BaseMutationOptions<ValidateTestMutation, ValidateTestMutationVariables>;
export const CertificatePdfDocument = gql`
    query certificatePdf($testId: ID!) {
  certificatePdf(testId: $testId) {
    base64
  }
}
    `;

/**
 * __useCertificatePdfQuery__
 *
 * To run a query within a React component, call `useCertificatePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificatePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificatePdfQuery({
 *   variables: {
 *      testId: // value for 'testId'
 *   },
 * });
 */
export function useCertificatePdfQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CertificatePdfQuery, CertificatePdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CertificatePdfQuery, CertificatePdfQueryVariables>(CertificatePdfDocument, options);
      }
export function useCertificatePdfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CertificatePdfQuery, CertificatePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CertificatePdfQuery, CertificatePdfQueryVariables>(CertificatePdfDocument, options);
        }
export type CertificatePdfQueryHookResult = ReturnType<typeof useCertificatePdfQuery>;
export type CertificatePdfLazyQueryHookResult = ReturnType<typeof useCertificatePdfLazyQuery>;
export type CertificatePdfQueryResult = Apollo.QueryResult<CertificatePdfQuery, CertificatePdfQueryVariables>;
export const ProductsDocument = gql`
    query products {
  products {
    id
    name
    variants {
      id
      name
      filters {
        id
        name
        insulationEffectLowerLimit250
        insulationEffectLowerLimit500
        insulationEffectUpperLimit250
        insulationEffectUpperLimit500
      }
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;