import React, { FunctionComponent } from "react";
import { Theme, makeStyles, Grid, Slider, Typography } from "@material-ui/core";
import { OverviewDiagramThumb } from "./overview-diagram-thumb";

const RAIL_HEIGHT = 2;

const useStyles = makeStyles((theme: Theme) => ({
  graphicPlaceholder: {
    width: "100%",
    height: 160,
  },
  root: {
    color: theme.palette.primary.main,
    height: RAIL_HEIGHT,
    padding: `4px 0`,
  },
  thumb: {
    height: 0,
  },
  track: {
    height: RAIL_HEIGHT,
    borderRadius: 90,
    backgroundColor: theme.palette.primary.main,
  },
  rail: {
    height: RAIL_HEIGHT,
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
  },
  markLabel: {
    width: 1,
    height: RAIL_HEIGHT,
    backgroundColor: theme.palette.primary.main,
  },
  left: {
    marginRight: theme.spacing(3),
  },
  right: {
    marginLeft: theme.spacing(3),
  },
  sliderContainer: {
    position: "relative",
  },
  leftLine: {
    height: RAIL_HEIGHT,
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    borderRadius: `2px 0 0 2px`,
  },
  rightLine: {
    height: RAIL_HEIGHT,
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    borderRadius: `0 2px 2px 0`,
  },
  seperator: {
    height: theme.spacing(10),
    width: RAIL_HEIGHT,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
  },
}));

interface IOverviewDiagramProps {
  min: number;
  max: number;
  testResultLeft: number;
  testResultRight: number;
  showLeftRightSeparately: boolean;
}

export const OverviewDiagram: FunctionComponent<IOverviewDiagramProps> = (props) => {
  const classes = useStyles();
  const { min, max, testResultLeft, testResultRight, showLeftRightSeparately } = props;
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item className={classes.left}>
        <Typography color="primary" align="center">
          min
        </Typography>
        <Typography color="primary" align="center">
          ({min} dB)
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid item container xs className={classes.sliderContainer} alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item className={classes.leftLine} />
              <Grid item className={classes.seperator} />
            </Grid>
          </Grid>
          <Grid item xs alignItems="center">
            <Slider
              min={min}
              max={max}
              value={showLeftRightSeparately ? [testResultLeft, testResultRight] : testResultLeft}
              valueLabelDisplay="on"
              valueLabelFormat="Ihr Wert"
              ThumbComponent={(props) => (
                <OverviewDiagramThumb {...props} showLeftRightSeparately={showLeftRightSeparately} />
              )}
              classes={{
                root: classes.root,
                thumb: classes.thumb,
                track: classes.track,
                rail: classes.rail,
                mark: classes.markLabel,
              }}
            />
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item className={classes.seperator} />
              <Grid item className={classes.rightLine} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.right}>
        <Typography color="primary" align="center">
          max
        </Typography>
        <Typography color="primary" align="center">
          ({max} dB)
        </Typography>
      </Grid>
    </Grid>
  );
};
