import React, { FunctionComponent, createContext, useMemo, useCallback, useState } from "react";
import {
  ProductResponse,
  ProductVariantFilterResponse,
  ProductVariantResponse,
} from "../../api/neuroth-earwear/graphql/generated";
import { useSessionState } from "../../hooks/use-session-state";
import { SessionStorageKey } from "../../models/session-storage-keys";

interface IAppContextProviderProps {}

export interface IAppContextProps {
  name: string | null;
  serialNumber: string | null;
  setName: (value: string | null) => void;
  productFamily: ProductResponse | null;
  productVariant: ProductVariantResponse | null;
  productFilter: ProductVariantFilterResponse | null;
  volume: number | null;
  setSerialNumber: (value: string | null) => void;
  setProductFamily: (value: ProductResponse | null) => void;
  setProductVariant: (value: ProductVariantResponse | null) => void;
  setProductFilter: (value: ProductVariantFilterResponse | null) => void;
  setVolume: (value: number | null) => void;
  didUserSkipOnboarding: boolean;
  isSerialNumberValid: boolean;
  purgeSession: () => void;
  progress: number;
  setProgress: (value: number) => void;
  isDataInputSkipped: boolean;
  setIsDataInputSkipped: (value: boolean) => void;
}

export const AppContext = createContext<IAppContextProps>({} as IAppContextProps);

export const AppContextProvider: FunctionComponent<IAppContextProviderProps> = (props) => {
  const [name, setName] = useSessionState<string | null>(SessionStorageKey.APP_CONTEXT_NAME, "");
  const [serialNumber, setSerialNumber] = useSessionState<string | null>(
    SessionStorageKey.APP_CONTEXT_SERIAL_NUMBER,
    "",
  );
  const [volume, setVolume] = useSessionState<number | null>(SessionStorageKey.APP_CONTEXT_VOLUME, -50);

  const [progress, setProgress] = useState<number>(0);

  const [isDataInputSkipped, setIsDataInputSkipped] = useSessionState<boolean>(SessionStorageKey.APP_CONTEXT_SKIP);

  const [productFamily, setProductFamily] = useSessionState<ProductResponse | null>(
    SessionStorageKey.APP_CONTEXT_PRODUCT_FAMILY,
  );
  const [productVariant, setProductVariant] = useSessionState<ProductVariantResponse | null>(
    SessionStorageKey.APP_CONTEXT_PRODUCT_VARIANT,
  );
  const [productFilter, setProductFilter] = useSessionState<ProductVariantFilterResponse | null>(
    SessionStorageKey.APP_CONTEXT_PRODUCT_FILTER,
  );

  const didUserSkipOnboarding = useMemo(() => name !== null, [name]);
  //Regex matches 8 digits only, !! is used so when serialNumber is null we get false instead of null
  const isSerialNumberValid = useMemo(() => {
    return !!serialNumber?.match(/^[0-9]{8}$/);
  }, [serialNumber]);

  const purgeSession = useCallback(() => {
    setName(null);
    setSerialNumber(null);
    setVolume(null);
    setProductFamily(null);
    setProductVariant(null);
    setProductFilter(null);
    setIsDataInputSkipped(false);
  }, [
    setName,
    setSerialNumber,
    setVolume,
    setProductFamily,
    setProductVariant,
    setProductFilter,
    setIsDataInputSkipped,
  ]);
  const onSetProgress = useCallback((v) => setProgress(v), [setProgress]);

  return (
    <AppContext.Provider
      value={{
        name,
        serialNumber,
        productFamily,
        productVariant,
        productFilter,
        volume,
        setName,
        setSerialNumber,
        setProductFamily,
        setProductVariant,
        setProductFilter,
        setVolume,
        didUserSkipOnboarding,
        isSerialNumberValid,
        purgeSession,
        progress,
        setProgress: onSetProgress,
        isDataInputSkipped,
        setIsDataInputSkipped,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
