import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router";
import { AppContext } from "../../app/context/app-context";
import { PrimaryActionButton } from "../../components/button/primary-action-button";
import { PageActions } from "../../components/containers/page-actions";
import { PageHeading } from "../../components/typography/page-heading";
import { ROUTES } from "../../router/router";
import { ProductSelector } from "./product-selector";
import { HearingProtectionTestContext } from "../hearing-protection-test/context/hearing-protection-test-context";
import { SpeakerType } from "../../api/neuroth-earwear/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  body: {
    width: "100%",
  },
  nextButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
  media: {
    height: 160,
    [theme.breakpoints.down("xs")]: {
      height: 120,
    },
    width: "100%",
  },
  image: {
    objectFit: "contain",
  },
  selectorContainer: {
    minHeight: "240px",
  },
}));

interface IProductPageProps {}

export const ProductPage: FunctionComponent<IProductPageProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { productFamily, setProductFamily } = useContext(AppContext);
  const { productVariant, setProductVariant } = useContext(AppContext);
  const { productFilter, setProductFilter } = useContext(AppContext);
  const { setProgress } = useContext(AppContext);
  const { setSpeakerType, setFrequency } = useContext(HearingProtectionTestContext);

  const isAllowedToProceed = useMemo(
    () => productFamily !== null && productVariant !== null && productFilter !== null,
    [productFamily, productVariant, productFilter],
  );

  const imageUrl = useMemo(() => {
    if (productFamily && productVariant) {
      return `/assets/${productFamily?.name.toUpperCase()}/${productVariant.name}.jpg`;
    } else return null;
  }, [productFamily, productVariant]);

  const handleNextButton = useCallback(() => {
    setFrequency(500);
    setSpeakerType(SpeakerType.SPEAKER);

    history.push(generatePath(ROUTES.ADJUST_VOLUME.PATH));
  }, [history, setFrequency, setSpeakerType]);

  useEffect(() => {
    setProductFamily(null);
    setProductVariant(null);
    setProductFilter(null);
    setSpeakerType(null);
    setProgress(30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs container direction="column" justify="space-between">
      <Grid container item alignItems="center">
        <Grid container item xs={12} alignItems="center">
          <PageHeading>{t("product.heading")}</PageHeading>
        </Grid>
        <Grid item>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography className={classes.body} variant="body1">
              {t("product.select_message")}
            </Typography>
            <Typography className={classes.body} variant="body1">
              {t("product.instruction_message")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.selectorContainer}>
          <ProductSelector
            productFamily={productFamily}
            productVariant={productVariant}
            productVariantFilter={productFilter}
            onProductFamilyChange={setProductFamily}
            onProductVariantChange={setProductVariant}
            onProductVariantFilterChange={setProductFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2} className={classes.media}>
            {imageUrl !== null && <img src={imageUrl!} className={classes.image} alt={t("product.product_image")} />}
          </Box>
        </Grid>
      </Grid>

      <Grid item>
        <PageActions
          actions={[
            <PrimaryActionButton disabled={!isAllowedToProceed} onClick={handleNextButton}>
              {t("general.next")}
            </PrimaryActionButton>,
          ]}
        />
      </Grid>
    </Grid>
  );
};
