import i18nImpl from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_DE } from "./de";
import LngDetector from "i18next-browser-languagedetector";

i18nImpl
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources: {
      de: {
        translation: TRANSLATIONS_DE,
      },
    },
    fallbackLng: "de",
    debug: false,
    react: {
      transSupportBasicHtmlNodes: true,
    },
    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ["querystring", "cookie", "navigator", "htmlTag", "path", "subdomain", "localStorage"],
    },
  });

export default i18nImpl;
export const i18n = i18nImpl;
