export const TRANSLATIONS_DE = {
  general: {
    next: "Weiter",
    powered_by: "powered by",
    welcome_message: "Willkommen bei der digitalen Dämmwertmessung.",
    explanation_message_1:
      "Mit dieser Webapplikation kannst du die Dämmleistung deiner Earwear Gehörschutzlösung ermitteln und erhälst so einen ersten Richtwert über die Schutzwirkung.",
    explanation_message_2:
      "Der Test dauert rund fünf Minuten und du erhältst dabei auch wichtige Hinweise zur Nutzung deines Gehörschutzes.",
    explanation_message_3:
      "Nach Abschluss der Messung erhältst du einen Bericht sowie den Termin für die nächste Dämmwertmessung.",
    explanation_message_4:
      "Bitte beachte, dass diese Webapplikation zur Ermittlung der Dämmleistung, derzeit keine offiziell zugelassen Funktionskontrolle laut EU-Verordnung 2016/425 darstellt!",
    confirm_and_finish: "Bestätigen und abschließen",
    version: "VERSION {{version}}",
  },
  speaker_type: {
    HEADPHONES: "Kopfhörer",
    SPEAKER: "Lautsprecher",
  },
  headphones_or_speaker: {
    heading: "Kopfhörer oder Lautsprecher?",
    content_1:
      "Den Funktionstest können Sie entweder mit Kapselkopfhörern (Over-Ear) oder den Lautsprechern Ihres Endgeräts durchführen. Wofür entscheiden Sie sich?",
    content_2:
      "Beide Ohren werden getrennt voneinander jeweils mit und ohne Gehörschutz getestet. Achtung: Bluetooth-Kopfhörer sind für die Durchführung des Tests nicht geeignet!",
    content_3: "Beide Ohren werden gleichzeitig jeweils mit und ohne Gehörschutz überprüft.",
    small_heading_1: "Durchführung mit Kopfhörern",
    small_heading_2: "Durchführung mit Lautsprechern",
  },
  adjust_volume: {
    heading: "Einstellung der Lautstärke",
    start_tone: "Ton starten",
    content: {
      SPEAKER: {
        content_1:
          "Bitte führen Sie den Test in einer ruhigen Umgebung durch und stellen Sie die Systemlautstärke Ihres Geräts auf 10%.",
        content_2: "",
        content_3:
          "Sie können Ihre Systemlautstärke entweder an den Tasten Ihres Geräts (bei Tablet/Smartphone), Ihrer Tastatur oder über Ihre Taskleiste einstellen.",
      },
      HEADPHONES: {
        content_1:
          "Schließen Sie nun bitte Ihre Kopfhörer an und setzen sie auf – am besten in einer ruhigen Umgebung, da Störgeräusche das Ergebnis verfälschen können.",
        content_2:
          "Als erstes müssen wir die Systemlautstärke einstellen. Starten Sie den Ton und stellen Sie Ihre Systemlautstärke auf 10%.",
        content_3:
          "Sie können Ihre Systemlautstärke entweder an den Tasten Ihres Geräts (bei Tablet/Smartphone), Ihrer Tastatur oder über Ihre Taskleiste einstellen.",
      },
    },
  },
  data_input: {
    your_information: "Deine Daten",
    data_message:
      "Damit wir dir ein Messprotokoll ausstellen können, benötigen wir deinen Vor- und Nachnamen sowie die Seriennummer deines Earwear Gehörschutzes. Du findest diese auf dem Etui deines Gehörschutzes.",
    next: "Weiter",
    skip_data_entry: "Dateneingabe überspringen",
    fields: {
      name: "Vor- und Nachname",
      serial_number: "Seriennummer",
      serial_number_hint: "Die Seriennummer muss aus 8 Zahlen bestehen",
    },
    skip_input_dialog: {
      heading: "Möchten Sie die Eingabe überspringen?",
      content: "Wenn Sie diesen Test ohne Eingabe Ihrer Daten starten, können wir Ihnen kein Zertifikat zusenden.",
      action_1: "Überspringen",
      action_2: "Daten eingeben",
    },
  },
  hearing_protection_test: {
    headings: {
      LEFT: {
        WITHOUT_PROTECTION: {
          LOW_FQ: "2. Testdurchlauf linkes Ohr ohne Gehörschutz",
          HIGH_FQ: "Linkes Ohr ohne Gehörschutz",
        },
        WITH_PROTECTION: {
          LOW_FQ: "2. Testdurchlauf linkes Ohr mit Gehörschutz",
          HIGH_FQ: "Linkes Ohr mit Gehörschutz",
        },
      },
      RIGHT: {
        WITHOUT_PROTECTION: {
          LOW_FQ: " 2. Testdurchlauf Rechtes Ohr ohne Gehörschutz",
          HIGH_FQ: "Rechtes Ohr ohne Gehörschutz",
        },
        WITH_PROTECTION: {
          LOW_FQ: "2. Testdurchlauf Rechtes Ohr mit Gehörschutz",
          HIGH_FQ: "Rechtes Ohr mit Gehörschutz",
        },
      },
      BOTH: {
        WITHOUT_PROTECTION: {
          LOW_FQ: "2. Testdurchlauf ohne Gehörschutz",
          HIGH_FQ: "Test ohne Gehörschutz",
        },
        WITH_PROTECTION: {
          LOW_FQ: "2. Testdurchlauf mit Gehörschutz",
          HIGH_FQ: "Test mit Gehörschutz",
        },
      },
    },
    headphone_prompts: {
      WEAR_PROTECTION: {
        LOW_FQ: "Bitte setzen Sie Ihren Gehörschutz ein.",
        HIGH_FQ: "Bitte setzen Sie Ihren Gehörschutz ein.",
      },
      REMOVE_PROTECTION: {
        LOW_FQ: "Bitte entfernen Sie nun nochmal Ihren Gehörschutz.",
        HIGH_FQ: "Bitte entfernen Sie nun Ihren Gehörschutz.",
      },

      wear_headphones_again: "",
    },

    headphone_prompt_underline: {
      WEAR_PROTECTION: {
        SPEAKER:
          "Achten Sie hierbei auf die Seitenmarkierungen der jeweiligen Ohrstücke\n(Rot = rechtes Ohr / Blau = linkes Ohr)",
        HEADPHONES:
          "Achten Sie hierbei auf die Seitenmarkierungen der jeweiligen Ohrstücke\n(Rot = rechtes Ohr / Blau = linkes Ohr)",
      },
      REMOVE_PROTECTION: {
        SPEAKER: "",
        HEADPHONES: "",
      },
    },
    headphone_prompt_underline_2: {
      WEAR_PROTECTION: {
        SPEAKER: "",
        HEADPHONES: "Und setzen bitte anschließend die Kopfhörer wieder auf.",
      },
      REMOVE_PROTECTION: {
        SPEAKER: "",
        HEADPHONES: "Und setzen bitte anschließend die Kopfhörer wieder auf.",
      },
    },
    adjust_volume_prompt_1: "Bitte regeln Sie die Lautstärke des Prüftons so, dass Sie ihn ",
    adjust_volume_prompt_2: {
      WITHOUT_PROTECTION: "gerade nicht mehr",
      WITH_PROTECTION: "gerade nicht mehr",
    },
    adjust_volume_prompt_3: " hören können.",
  },
  overview: {
    heading: "Dämmwertmessung erfolgreich!",
    body_1:
      "Dein Earwear Gehörschutz wurde erfolgreich vermessen und bietet dir den ermittelten Schutz.",
    body_2:
      "Bitte beachte, dass diese Webapplikation zur Ermittlung der Dämmleistung, derzeit keine offiziell zugelassen Funktionskontrolle laut EU-Verordnung 2016/425 darstellt!",
    body_3:
      "Auf den folgenden Seiten möchten wir dir noch einige wichtige Hinweise zur Verwendung deines Gehörschutzes geben.",
    diagram: {
      your_result: "Ihr Wert",
      r: "R",
      l: "L",
    },
  },
  product: {
    heading: "Ihr Produkt",
    select_message: "Bitte wählen Sie Ihr EARWEAR Produkt und den verbauten Filter aus.",
    instruction_message: "Sie finden diese Informationen auf dem Etui Ihres Gehörschutzes.",
    product_family: "Produktfamilie",
    product_variant: "Produkt",
    product_filter: "Filter",
    product_graphic: "Grafik",
    product_next: "Weiter",
    product_image: "Produktbild",
  },
  cancel_test: {
    cancel_test: "Prüfung abbrechen",
    heading: "Wollen Sie die Prüfung abbrechen?",
    content:
      "Wenn Sie die laufende Funktionsprüfung abbrechen, gehen alle bisherigen Informationen verloren. Dieser Vorgang kann nicht rückgängig gemacht werden. \n\nSind Sie sich sicher?",
    action_1: "Nein, Prüfung fortsetzen",
    action_2: "Ja, Prüfung abbrechen",
  },
  test_failure: {
    heading: "Oh nein!",
    body_1: "Leider war die Funktionsprüfung nicht erfolgreich und konnte deshalb nicht abgeschlossen werden!",
    body_2:
      "Bitte wenden Sie sich an Ihre Sicherheitsbeauftragten oder direkt an den EARWEAR Kundenservice. Wir helfen Ihnen gerne.",
    contact_customer_support: "Kontakt zum Kundenservice",
    retake_test: "Prüfung erneut starten",
    dialog_content: "Nutzer soll sich an den Sicherheitsbeauftragten oder an den Earwear Kunden-Service wenden",
    dialog_ok_button: "OK",
    dialog_btn: "Zurück zur Startseite",
    dialog_message:
      "Keine Sorge. Das EARWEAR Service-Team der ALA wurde informiert. \n" +
      "Bitte nehmen Sie zusätzlich mit Ihrem Sicherheitsbeauftragten Kontakt auf. Er kann Ihnen weiterhelfen. \n" +
      "Verwenden Sie diesen Gehörschutz nicht!",
    email: {
      subject: "Gehörschutz Funktionsprüfung <0>fehlgeschlagen.</0>",
      body_1: "Gehörschutz Funktionsprüfung <0>fehlgeschlagen</0>",
      body_2: "Bitte schnellstmöglich Kontakt zum verantwortlichen im Unternehmen des Trägers ausnehmen.",
    },
  },
  security_information: {
    heading: "Hinweise zur Verwendung",
    wear_headphones:
      "Setze deinen Earwear Gehörschutz vor Betreten des Lärmbereichs ein und entferne ihn erst nach Verlassen des Lärmbereichs.",
    warning: "Verwende deinen Earwear Gehörschutz nicht, falls er beschädigt wurde oder nicht funktioniert.",
    how_to_store: "Bewahre deinen Earwear Gehörschutz im mitgelieferten Etui auf.",
    clean: "Reinige deinen Earwear Gehörschutz regelmäßig.",
    read_instructions: "Bitte lese dir die Gebrauchsanweisung sorgfältig durch.",
    next_test: "Notiere das Datum deiner nächsten Dämmwertmessung:",
    acceptance_checkbox: "Ich habe diese Hinweise verstanden.",
    button: "WEITER - Ich habe diese Hinweise verstanden",
  },
  earwear_account: {
    heading: "Earwear Gehörschutzlösungen schützen dich auch privat",
    body_1:
      "Du bist auch außerhalb der Arbeit schädlichem Lärm oder störenden Geräuschen ausgesetzt? Oder kennst jemanden in deinem Umfeld, der Schwierigkeiten hat, sich bei Umgebungsgeräuschen zu konzentrieren oder einzuschlafen?",
    body_2:
      "Die individuell angefertigten Gehörschutzlösungen von Earwear sind perfekt zum Schutz der körperlichen und mentalen Gesundheit. Ob auf Reisen, im Großraumbüro, im Hobbykeller oder beim Rasenmähen. Gerne beraten wir dich in einem Neuroth Fachinstitut ganz in deiner Nähe. Vereinbare doch gleich einen Termin für eine unverbindliche Gehörschutzberatung.",
    action_1: "Online-Terminvereinbarung",
    action_2: "Zurück zur Startseite",
    dialog: {
      heading: "Account anlegen",
      fields: {
        name: "Vor- und Nachname",
        email: "E-Mail Adresse",
        password: "Passwort",
        confirm_password: "Passwort wiederholen",
      },
      action_1: "Abbrechen",
      action_2: "Account anlegen",
    },
  },
  summary: {
    heading: "Dämmwertmessung beendet!",
    body_1:
      "Dein Earwear Gehörschutz wurde erfolgreich vermessen und bietet dir den ermittelten Schutz.",
    body_2:
      "Ich bestätige hiermit, dass ich die Hinweise zur Nutzung verstanden habe und akzeptiere, dass diese Webapplikation zur Ermittlung der Dämmleistung, derzeit keine offiziell zugelassen Funktionskontrolle laut EU-Verordnung 2016/425 darstellt.",
    button_1: "Bestätigen und abschließen",
    button_2: "Download PDF",
    email_heading: "Ich möchte die Dämmwertmessung per E-Mail erhalten:",
    email_fail: "Bitte geben Sie eine gültige E-Mail ein.",
    dialog: {
      heading: "Wie wollen Sie Ihr Zertifikat erhalten?",
      content:
        "Wenn Sie diesen Test ohne Eingabe einer gültigen E-Mail-Adresse abschließen, können wir Ihnen kein Zertifikat zusenden. Geben Sie bitte Ihre E-Mail-Adresse ein oder laden Sie das Zertifikat gleich hier herunter.",
      left_button: "E-Mail-Adresse angeben",
      right_button: "Zertifikat herunterladen",
    },
    certificate_sent_dialog: {
      content: "Ihr Prüfprotokoll wurde erfolgreich versandt!",
    },
  },
};
