import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../components/typography/page-heading";
import { HearingProtectionPromptGraphicDictionary } from "../../models/hearing-protection-prompt-graphic-dictionary";
import { HearingProtectionTestContext } from "./context/hearing-protection-test-context";

export type IHearingProtectionTestPromptType = "REMOVE_PROTECTION" | "WEAR_PROTECTION";

const useStyles = makeStyles((theme: Theme) => ({
  underline: {
    paddingTop: theme.spacing(6),
    whiteSpace: "pre-line",
  },
  underline_2: {
    paddingTop: (props: { type: IHearingProtectionTestPromptType }) => {
      return props.type === "WEAR_PROTECTION" ? theme.spacing(3) : theme.spacing(16);
    },
  },
}));

export interface IHearingProtectionTestPromptProps {
  type: IHearingProtectionTestPromptType;
}

export const HearingProtectionTestPrompt: FunctionComponent<IHearingProtectionTestPromptProps> = (props) => {
  const classes = useStyles(props);
  const { type } = props;
  const { t } = useTranslation();
  const { speakerType, frequencyType } = useContext(HearingProtectionTestContext);

  const Graphic = useMemo(() => HearingProtectionPromptGraphicDictionary[type], [type]);

  return (
    <Grid container item alignItems="flex-start" style={{ flex: 1 }} justify="space-between">
      <Grid container item xs={12} alignItems="center">
        <PageHeading>{t(`hearing_protection_test.headphone_prompts.${type}.${frequencyType}`)}</PageHeading>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12} justify="center">
          <Graphic />
        </Grid>
        <Grid container item xs={12} className={classes.underline}>
          <Typography>{t(`hearing_protection_test.headphone_prompt_underline.${type}.${speakerType}`)}</Typography>
        </Grid>
        <Grid container item xs={12} className={classes.underline_2}>
          <Typography>{t(`hearing_protection_test.headphone_prompt_underline_2.${type}.${speakerType}`)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
