import React, { FunctionComponent, useCallback } from "react";
import { Theme, makeStyles, Slider, Grid, useTheme } from "@material-ui/core";
import { VolumeDown } from "../../assets/icons/volume-down";
import { VolumeUp } from "../../assets/icons/volume-up";

const RAIL_HEIGHT = 8;
const ICON_SIZE = RAIL_HEIGHT * 3;
const MAX_VOLUME = -0;
const MIN_VOLUME = -80;
const VOLUME_STEP = 2;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: RAIL_HEIGHT,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.secondary.main,
    marginTop: -RAIL_HEIGHT,
    marginLeft: -RAIL_HEIGHT * 1.5,
    "&:focus": {
      boxShadow: `0px 0px 0px 0px ${theme.palette.secondary.main}00`,
    },
    "&:hover": {
      boxShadow: `0px 0px 0px ${theme.spacing(2)}px ${theme.palette.secondary.main}55`,
    },
    "&:active": {
      boxShadow: `0px 0px 0px ${theme.spacing(4)}px ${theme.palette.secondary.main}55`,
    },
  },
  track: {
    height: RAIL_HEIGHT,
    borderRadius: 90,
    backgroundColor: theme.palette.secondary.light,
  },
  rail: {
    height: RAIL_HEIGHT,
    borderRadius: 4,
    backgroundColor: theme.palette.grey["200"],
  },
  markLabel: {
    width: 1,
    height: RAIL_HEIGHT,
    backgroundColor: theme.palette.background.default,
  },
  volumeIcon: {
    cursor: "pointer",
  },
}));

interface IVolumeSliderProps {
  volume: number;
  onChange: (value: number) => void;
}

export const VolumeSlider: FunctionComponent<IVolumeSliderProps> = (props: IVolumeSliderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { volume, onChange } = props;

  const changeVolume = useCallback(
    (newVolume: number) => {
      onChange(newVolume > MAX_VOLUME ? MAX_VOLUME : newVolume < MIN_VOLUME ? MIN_VOLUME : newVolume);
    },
    [onChange],
  );

  return (
    <Grid container alignItems="center" spacing={4}>
      <Grid item>
        <VolumeDown
          className={classes.volumeIcon}
          color={theme.palette.grey["800"]}
          width={ICON_SIZE}
          height={ICON_SIZE}
          onClick={() => changeVolume(volume - VOLUME_STEP)}
        />
      </Grid>
      <Grid item xs>
        <Slider
          min={MIN_VOLUME}
          step={VOLUME_STEP}
          max={MAX_VOLUME}
          value={volume}
          onChange={(event, value) => changeVolume(value as number)}
          marks
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            track: classes.track,
            rail: classes.rail,
            mark: classes.markLabel,
          }}
        />
      </Grid>
      <Grid item>
        <VolumeUp
          className={classes.volumeIcon}
          color={theme.palette.grey["800"]}
          width={ICON_SIZE}
          height={ICON_SIZE}
          onClick={() => changeVolume(volume + VOLUME_STEP)}
        />
      </Grid>
      {/* {`${volume.toFixed(2)}dB`} */}
    </Grid>
  );
};
