import { useCallback, useState } from "react";
import { SessionStorageKey } from "../models/session-storage-keys";

export const useSessionState = <T>(
  sessionStorageKey: SessionStorageKey,
  initialValue: T | null = null,
): [T, (value: T) => void] => {
  // console.log(sessionStorageKey);
  // console.log(
  //   sessionStorage.getItem(sessionStorageKey) === null || sessionStorage.getItem(sessionStorageKey) === undefined,
  // );
  // console.log(initialValue);
  // console.log(sessionStorage.getItem(sessionStorageKey));
  // console.log("----");

  const [value, setValue] = useState<T>(
    sessionStorage.getItem(sessionStorageKey) === null || sessionStorage.getItem(sessionStorageKey) === undefined
      ? initialValue
      : JSON.parse(sessionStorage.getItem(sessionStorageKey) || ""),
  );

  const onChangeValue = useCallback(
    (_value: T) => {
      setValue(_value);
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(_value));
    },
    [setValue, sessionStorageKey],
  );

  return [value, onChangeValue];
};
