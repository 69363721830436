import React, { FunctionComponent } from "react";
import { IImageAssetProps } from "../types/asset-type";

export const VolumeDown: FunctionComponent<IImageAssetProps> = (props) => {
  const { color, ...rest } = props;
  return (
    <svg width="20px" height="26px" viewBox="0 0 20 26" {...rest}>
      <title>Icons/Lautstärke/leiser</title>
      <g id="Webapp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3a/Lautstärke-einstellen/Kopfhörer" transform="translate(-138.000000, -639.000000)" fill={color}>
          <g id="Group-5" transform="translate(138.000000, 494.000000)">
            <g id="Group-Copy-2" transform="translate(0.000000, 121.000000)">
              <g id="Combined-Shape-Copy-3" transform="translate(0.000000, 24.000000)">
                <path d="M16.0017058,22.9616555 L7.74375858,17.8350849 L7.74375858,8.18416146 L16.0017058,3.04987017 C17.1097244,9.64106487 17.1097244,16.3704608 16.0017058,22.9616555 M2.3196481,16.9240378 L2.3196481,9.0874879 C2.3196481,8.87439551 2.49284849,8.70145096 2.70625612,8.70145096 L5.41251224,8.70145096 L5.41251224,17.3100747 L2.70625612,17.3100747 C2.49284849,17.3100747 2.3196481,17.1371301 2.3196481,16.9240378 M17.9772728,0.94596885 C17.8597439,0.317114676 17.2543158,-0.0974889967 16.6245313,0.0198662328 C16.4834194,0.0461167447 16.3481066,0.0982317315 16.2259385,0.173894972 L6.23985339,6.38522932 L2.70625612,6.38522932 C1.21162952,6.38522932 0,7.59506909 0,9.0874879 L0,16.9240378 C0,18.4164566 1.21162952,19.6262963 2.70625612,19.6262963 L6.23985339,19.6262963 L16.2259385,25.8260496 C16.7702826,26.1630598 17.4851208,25.9955198 17.8230162,25.4519798 C17.8984047,25.3299921 17.9509834,25.1948792 17.9772728,25.0539757 C19.5430353,17.093894 19.5430353,8.90605053 17.9772728,0.94596885"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
