export enum SessionStorageKey {
  APP_CONTEXT_NAME = "APP_CONTEXT_NAME",
  APP_CONTEXT_SERIAL_NUMBER = "APP_CONTEXT_SERIAL_NUMBER",
  APP_CONTEXT_PRODUCT_FAMILY = "APP_CONTEXT_PRODUCT_FAMILY",
  APP_CONTEXT_PRODUCT_VARIANT = "APP_CONTEXT_PRODUCT_VARIANT",
  APP_CONTEXT_PRODUCT_FILTER = "APP_CONTEXT_PRODUCT_FILTER",
  APP_CONTEXT_VOLUME = "APP_CONTEXT_VOLUME",
  APP_CONTEXT_SKIP = "APP_CONTEXT_SKIP",

  HEARING_TEST_CONTEXT_SPEAKER_TYPE = "HEARING_TEST_CONTEXT_SPEAKER_TYPE",
  HEARING_TEST_CONTEXT_READINGS = "HEARING_TEST_CONTEXT_READINGS",
  HEARING_TEST_CONTEXT_READINGS_NORMALIZED = "HEARING_TEST_CONTEXT_READINGS_NORMALIZED",
  HEARING_TEST_CONTEXT_FREQUENCY = "HEARING_TEST_CONTEXT_FREQUENCY",
  HEARING_TEST_SUBMISSION_DATE = "HEARING_TEST_SUBMISSION_DATE",
  HEARING_TEST_NEXT_TEST_DATE = "HEARING_TEST_NEXT_TEST_DATE",
  HEARING_TEST_ID = "HEARING_TEST_ID",
}
