import React, { FunctionComponent, SVGProps } from "react";

export const SetVolume: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
  const { ...rest } = props;
  return (
    <svg viewBox="0 0 350 76" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <title>Group 2</title>
      <defs>
        <polygon id="path-1" points="0 0 53.6067083 0 53.6067083 74.7642236 0 74.7642236"></polygon>
        <polygon id="path-3" points="0 0 69 0 69 26 0 26"></polygon>
      </defs>
      <g id="Webapp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3a/Lautstärke-einstellen/Kopfhörer" transform="translate(-209.000000, -661.000000)">
          <g id="Group-2" transform="translate(209.000000, 661.000000)">
            <text
              id="50%"
              fontFamily="OpenSans-SemiBold, Open Sans"
              fontSize="53.7894922"
              fontWeight="500"
              letterSpacing="0.504276487"
              fill="#FBBA00"
            >
              <tspan x="239.774169" y="57">
                10%
              </tspan>
            </text>
            <g id="Group-8" transform="translate(0.000000, 1.235776)">
              <path
                d="M62.8598051,56.9610113 C63.4551714,56.9610113 64.0295392,56.7385715 64.4766815,56.3344726 C74.7498371,47.1254671 75.7367617,31.1406996 66.6778073,20.7008608 C65.9935067,19.9124355 65.2536221,19.1610835 64.4791519,18.4653414 C64.0295392,18.0600067 63.4551714,17.837567 62.8598051,17.8363312 C62.1643877,17.837567 61.5010855,18.1403322 61.0378856,18.6704802 C60.1337195,19.7023535 60.2251242,21.2890904 61.2404584,22.2072722 C69.4817106,29.5935076 70.2747087,42.4208664 63.0055587,50.7981945 C62.4558948,51.430912 61.8617638,52.0352066 61.237988,52.5937775 C60.2251242,53.5082521 60.1337195,55.0949889 61.0378856,56.128098 C61.5010855,56.6570103 62.1643877,56.9610113 62.8598051,56.9610113"
                id="Fill-1"
                fill="#000000"
              ></path>
              <g id="Group-5">
                <mask id="mask-2" fill="white">
                  <use href="#path-1"></use>
                </mask>
                <g id="Clip-4"></g>
                <path
                  d="M4.86248701,25.8524418 C4.86248701,24.7142918 5.78024036,23.7874595 6.90797766,23.7874595 L14.7428491,23.7874595 L15.6692489,23.7874595 L15.6692489,24.7142918 L15.6692489,50.083545 L15.6692489,51.0103773 L14.7428491,51.0103773 L6.90797766,51.0103773 C5.78024036,51.0103773 4.86248701,50.083545 4.86248701,48.9453949 L4.86248701,25.8524418 Z M20.5655804,51.630737 L20.5655804,23.1905795 L20.5655804,22.6802038 L20.9966651,22.4070973 L44.9027194,7.27748708 L46.0848055,6.52984237 L46.3120822,7.90896881 C49.5445998,27.4774877 49.5445998,47.320349 46.3120822,66.8888679 L46.0848055,68.2679944 L44.9039546,67.5215854 L20.9966651,52.4142192 L20.5655804,52.1411127 L20.5655804,51.630737 Z M-0.000494079867,48.9453949 C-0.000494079867,52.8257328 3.09862188,55.9819056 6.90797766,55.9819056 L17.1379013,55.9819056 L17.4059396,55.9819056 L17.6332164,56.1252557 L46.5418294,74.3949736 C46.9259765,74.63595 47.3657076,74.7644708 47.8128498,74.7644708 C48.6601968,74.7644708 49.4321966,74.3270059 49.8805741,73.5929548 C50.04115,73.3297344 50.1510828,73.0405627 50.2066668,72.7365617 C54.7398496,49.2741115 54.7398496,25.4903593 50.207902,2.03655947 C49.9905068,0.8526857 48.9875247,0 47.8214962,0 C47.6732723,0 47.5213427,0.0135935401 47.374354,0.0420163968 C47.0766708,0.0988621101 46.7962805,0.208846208 46.5418294,0.369497137 L17.6332164,18.672581 L17.4059396,18.8159311 L17.1379013,18.8159311 L6.90797766,18.8159311 C3.09862188,18.8159311 -0.000494079867,21.972104 -0.000494079867,25.8524418 L-0.000494079867,48.9453949 Z"
                  id="Fill-3"
                  fill="#000000"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                d="M77.5616457,64.953395 C78.1310727,64.953395 78.6856774,64.7470203 79.122938,64.3725801 C93.7439965,50.7592675 94.7457434,27.5489156 81.3265342,12.6578103 C80.6187648,11.8718565 79.8677634,11.1081467 79.0945284,10.3889248 C78.6856774,10.0392001 78.1310727,9.83282547 77.5628809,9.83282547 C76.846465,9.83282547 76.1695756,10.1528916 75.707611,10.7114625 C74.8899088,11.6963762 74.952904,13.1780721 75.8508941,14.0863678 C88.4795755,25.8423084 89.3442153,45.8915444 77.7531016,58.7547407 C77.1577354,59.4146453 76.5290187,60.0584847 75.8298957,60.7208609 C74.8923792,61.6699371 74.8886736,63.2517309 75.8434829,64.2267585 C76.301742,64.6951177 76.9119306,64.953395 77.5616457,64.953395"
                id="Fill-6"
                fill="#000000"
              ></path>
            </g>
            <g id="Group-3" transform="translate(130.774169, 27.000000)">
              <mask id="mask-4" fill="white">
                <use href="#path-3"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                d="M68.2518425,11.1812778 L57.8388476,0.728949216 C56.8740304,-0.24338872 55.1868125,-0.24338872 54.2195713,0.728949216 C53.7347385,1.21450971 53.4692926,1.85948982 53.4692926,2.54584601 C53.4692926,3.2322022 53.7347385,3.87839925 54.2195713,4.3627428 L60.259375,10.4292066 L2.56064405,10.4292066 C1.14856873,10.4292066 -0.000484832728,11.5816522 -0.000484832728,12.9993915 C-0.000484832728,14.4171308 1.14856873,15.5695764 2.56064405,15.5695764 L60.259375,15.5695764 L54.2195713,21.6372572 C53.7347385,22.1216007 53.4692926,22.7677978 53.4692926,23.454154 C53.4692926,24.1392932 53.7347385,24.7867072 54.2195713,25.2710508 C55.1868125,26.2446057 56.8740304,26.2409548 57.8388476,25.2722677 L68.2518425,14.8162883 C69.2493858,13.8159607 69.2493858,12.1840393 68.2518425,11.1812778"
                id="Fill-1"
                fill="#000000"
                mask="url(#mask-4)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
